import config from '../config/config'
import { axios } from './index'

const baseUrl = config.baseUrl + '/exam/api/v1'
const baseUrl1 = config.baseUrl + '/question/api/v1'
const examReportUrl = config.baseUrl + '/exam/api/v2/exam'

export default {
  getStudentsRank(examId) {
    // 本场考试排名
    return axios.get(`${baseUrl}/exam/rank?examId=${examId}`)
  },
  getNowExamInfo(examId) {
    //本场考试基本信息
    return axios.get(`${baseUrl}/enter/report/exam-info?examId=${examId}`)
  },
  getRandomNowExamInfo(payload) {
    //本场考试基本信息
    return axios.post(`${baseUrl}/enter/report/random/exam-info`, payload)
  },
  getRandomStuPointResult(payload) {
    // 获取人员分数结果
    return axios.post(`${baseUrl}/enter/report/random/exam-section-info`, payload)
  },
  getRandomStudentsRank(payload) {
    // 本场考试排名
    return axios.post(`${baseUrl}/exam/random/rank`, payload)
  },
  getRandomExamPointsByPeoNum(payload, interval = 10) {
    // 本场考试区间分布
    return axios.post(`${baseUrl}/exam/random/statistics?interval=${interval}`, payload)
  },
  getStuPointResult(examId) {
    // 获取人员分数结果
    return axios.get(`${baseUrl}/enter/report/exam-section-info?examId=${examId}`)
  },
  getAvgHisRRate(examId) {
    // 本场考试平均掌握度
    //  /statistics/exam/user/knowledge/rate?examId=927
    return axios.get(`${baseUrl1}/statistics/exam/user/knowledge/rate?examId=${examId}`)
  },
  getAvgHisRank(examId) {
    // 本场考试平均掌握度排名
    return axios.get(`${baseUrl1}/statistics/exam/user/knowledge/rate/rank?examId=${examId}`)
  },
  getExamPointsByPeoNum(examId, interval = 10) {
    // 本场考试区间分布
    return axios.get(`${baseUrl}/exam/statistics?examId=${examId}&interval=${interval}`)
  },
  getExamEvaluation(examId) {
    return axios.get(`${baseUrl1}/exam/questions/rate?examId=${examId}`)
  },

  /**
   * 个人报告
   */
  getCateRate(examId, userId) {
    return axios.get(`${baseUrl1}/statistics/exam/user/category/rate?userId=${userId}&examId=${examId}`)
  },
  getKnowLedgeScore(examId, userId) {
    return axios.get(`${baseUrl1}/statistics/exam/user/knowledge/score?userId=${userId}&examId=${examId}`)
  },
  //  answerPaperId
  getPerExamRate(answerPaperId) {
    return axios.get(`${baseUrl}/enter/report/personal-question-info?answerPaperId=${answerPaperId}`)
  },
  getPersonInfo(answerPaperId) {
    return axios.get(`${baseUrl}/enter/report/personal-info?answerPaperId=${answerPaperId}`)
  },
  getPerSectionInfo(answerPaperId) {
    return axios.get(`${baseUrl}/enter/report/personal-section-info?answerPaperId=${answerPaperId}`)
  },
  getExamReportById(id) {
    return axios.get(`${baseUrl}/enter/report/download/exam-export?examId=${id}`)
  },
  // 获取企业题库分类树
  getQuestionTree(categoryId) {
    return axios.get(`${baseUrl1}/question/bank/category/tree?categoryId=${categoryId}`)
  },
  // 添加题库分类
  addNewBankCategory(data) {
    return axios.post(`${baseUrl1}/question/bank/category/add`, data)
  },
  // 删除题库分类
  bankDelete(categoryId) {
    return axios.post(`${baseUrl1}/question/bank/category/delete?categoryId=${categoryId}`)
  },
  // 修改题库分类
  bankModify(data) {
    return axios.post(`${baseUrl1}/question/bank/category/modify`, data)
  },
  // 移除题库分类关系
  reMoveCategory(questionBankIds) {
    return axios.post(`${baseUrl1}/question/bank/category/delete/course-category?questionBankIds=${questionBankIds}`)
  },
  // 移动题库到一个分类下
  categoryMove(data) {
    return axios.post(`${baseUrl1}/question/bank/category/move?categoryId=${data.categoryId}&questionBankIds=${data.questionBankIds}`)
  },
  exportSingleReport(examId, userId) {
    return axios.get(`${baseUrl}/enter/report/download/personal-export/latest?examId=${examId}&userId=${userId}`)
  },
  // 考试结果 基本信息
  examBasicInfo(examId) {
    return axios.get(`${examReportUrl}/simple?examId=${examId}`)
  }
}
