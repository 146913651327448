<template>
  <div class="result-container">
    <div class="top">
      <div class="block shadow" style="width: 25%;">
        <p>基本信息</p>
        <ul style="margin-top: 8px">
          <template v-for="(item, index) in baseInfo">
            <li :key="index">
              <div class="label">
                <span class="dot" :style="{ backgroundColor: item.color }"></span>
                {{ item.label }}
              </div>
              <div style="margin-right: 5px">{{ item.value }}</div>
            </li>
          </template>
        </ul>
      </div>
      <div class="block pie-container shadow" style="width: 33%">
        <p>技能下题目数量</p>
        <div class="chart" ref="skillQuestionCountChart"></div>
      </div>
      <div class="block shadow" style="width: 42%;position: relative;">
        <p style="position:absolute">技能得分情况</p>
        <div style="height: 188px;margin-top:14px" ref="skillScoreChart"></div>
      </div>
    </div>
    <div
      style="background:#F8F8F8;box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);"
      :style="{
        height: isSkillPackUp ? '100%' : '60px',
        overflow: 'hidden'
      }"
    >
      <div class="block" style="margin-top: 10px;background:#ffffff;">
        <div style="display:flex;justify-content:space-between">
          <p>技能分布情况</p>
          <div class="pack-up" @click="isSkillPackUp = !isSkillPackUp">
            <span class="text">{{ !isSkillPackUp ? '展开' : '收起' }}</span>
            <YTIcon :class="['icon', isSkillPackUp ? 'active' : '']" :href="'#icon-open'"></YTIcon>
          </div>
        </div>
        <h4 style="margin-top: 20px; margin-left: 10px; font-size: 14px">总体技能分析</h4>
        <div class="analysis-container ">
          <div class="item has-border" :style="{ height: `${skillAnalysisList.length * 36 + 36}px` }" ref="skillAnalysisRadarChart"></div>
          <div class="item has-border" style="width: calc(33% + 48px); padding: 0 24px; flex-shrink: 0">
            <el-table :data="skillAnalysisList" class="yt-table ">
              <el-table-column label="技能名称" min-width="60" prop="abilityName" show-overflow-tooltip align="center" :width="120" />
              <el-table-column label="描述" min-width="60" prop="description" show-overflow-tooltip />
              <el-table-column label="平均掌握率" min-width="60" show-overflow-tooltip>
                <template slot-scope="scope">{{ $roundFloat(scope.row.averageRate, 2) }}%</template>
              </el-table-column>
              <el-table-column label="题目数量" min-width="50" prop="questionCount" show-overflow-tooltip align="center" />
            </el-table>
          </div>
          <div class="item" :style="{ height: `${skillAnalysisList.length * 36 + 36}px` }" ref="skillOverallBarChart"></div>
        </div>
      </div>
      <template v-for="(skill, index) in skillList">
        <div :key="skill.id" class="block skill-block-wrapper list" style="background:#ffffff">
          <h3>{{ skill.abilityName }}技能掌握情况</h3>
          <el-row class="skill-block" :gutter="17">
            <el-col :span="13">
              <div :ref="'distributionChart-' + skill.id" style="height:298px"></div>
            </el-col>
            <el-col :span="11">
              <el-table :data="skill.userAbilityList" class="yt-table" height="298px">
                <el-table-column label="姓名" min-width="80" prop="name" align="left" />
                <el-table-column label="电话" min-width="90" prop="phone" />
                <el-table-column label="掌握率" min-width="70">
                  <template slot-scope="scope">{{ $roundFloat(scope.row.abilityRate, 2) }}%</template>
                </el-table-column>
                <el-table-column label="平均掌握率" min-width="70">
                  <template slot-scope="scope">{{ $roundFloat(scope.row.averageRate, 2) }}%</template>
                </el-table-column>
                <el-table-column label="差异" min-width="50" align="center">
                  <template slot-scope="scope">{{ $roundFloat(scope.row.difference, 1) }}%</template>
                </el-table-column>
              </el-table>
              <Page
                class="yt-page"
                :class="'yt-page-' + (skill.total.toString().length > 1 ? skill.total.toString().length : 2)"
                :total="skill.total"
                :current="skill.pageNum + 1"
                :page-size-opts="[10, 20, 40, 100]"
                :page-size="skill.pageSize"
                show-elevator
                show-sizer
                show-total
                @on-change="changePage($event, index, skill.pageSize)"
                @on-page-size-change="changePageSize($event, index)"
              />
            </el-col>
          </el-row>
          <h4 class="description" v-if="skill.abilityDescription !== null && skill.abilityDescription !== ''">
            技能描述:{{ skill.abilityDescription }}
          </h4>
        </div>
      </template>
      <div class="block" style="margin-top: 10px;padding:10px 10px 0 10px;background:#ffffff">
        <p style="font-size:14px">评价级别占比</p>
        <div class="skill-pie-list">
          <template v-for="(item, index) in skillPieList">
            <div class="skill-pie-item" :class="{ 'has-border': (index + 1) % 2 === 0 }" :key="index">
              <div :ref="'skillPieChart-' + item.id" class="skillPieChart"></div>
              <div class="skill-pie-title">- {{ item.abilityName }} -</div>
              <el-divider v-if="index < skillPieList.length - 2"></el-divider>
            </div>
          </template>
        </div>
      </div>
      <template v-for="(item, index) in subSkillList">
        <div :key="item.id" class="block skill-block-wrapper list" style="padding:20px 20px 0 20px;background:#ffffff;">
          <h4 v-if="index === 0" style=" font-size: 14px;margin-bottom:20px">详细技能分析</h4>
          <h3>{{ item.abilityName }}详细技能掌握情况</h3>
          <div v-for="(subSkill, sIndex) in item.abilityRateVOList" :key="subSkill.id">
            <div style="margin-bottom:5px">
              <p>技能名称：{{ item.abilityName }}-{{ subSkill.abilityName }}</p>
              <p style="margin-bottom:10px">技能描述：{{ subSkill.abilityDescription }}</p>
              <el-row class="skill-block">
                <el-col :span="13">
                  <div :ref="'distributionChart-' + subSkill.id" class="distributionChart" style="height:298px"></div>
                </el-col>
                <el-col :span="11">
                  <el-table :data="subSkill.userAbilitySubList" class="yt-table " height="298px">
                    <el-table-column label="姓名" min-width="80" prop="name" align="left" />
                    <el-table-column label="电话" min-width="90" prop="phone" />
                    <el-table-column label="掌握率" min-width="70">
                      <template slot-scope="scope">{{ $roundFloat(scope.row.abilityRate, 2) }}%</template>
                    </el-table-column>
                    <el-table-column label="平均掌握率" min-width="70">
                      <template slot-scope="scope">{{ $roundFloat(scope.row.averageRate, 2) }}%</template>
                    </el-table-column>
                    <el-table-column label="差异" min-width="50" prop="difference" align="center" />
                  </el-table>
                  <Page
                    class="yt-page"
                    :class="'yt-page-' + (subSkill.total.toString().length > 1 ? subSkill.total.toString().length : 2)"
                    :total="subSkill.total"
                    :current="subSkill.pageNum + 1"
                    :page-size-opts="[10, 20, 40, 100]"
                    :page-size="subSkill.pageSize"
                    show-elevator
                    show-sizer
                    show-total
                    style="margin-bottom:20px"
                    @on-change="changePageSub($event, index, sIndex, subSkill.pageSize)"
                    @on-page-size-change="changePageSizeSub($event, index, sIndex)"
                  />
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import barLineChart from './reportCharts/barLineChart'
import radarChart from './reportCharts/radarChart'
import barHorizontalChart from './reportCharts/barHorizontalChart'
import pieLoopChart from './reportCharts/pieLoopChart'
import barStackChart from './reportCharts/barStackChart'
import pieChart from './reportCharts/pieChart'
import { pdfD2 } from '@util/pdfDown2'
import examProcessApi from '@api/examProcess'
import examResultApi from '@api/examResult'

export default {
  name: 'SkillReport',
  components: { YTIcon },
  data() {
    return {
      formData: {},
      baseInfo: [
        {
          key: 'candidateNum',
          label: '考试总人数',
          color: '#448BFF',
          value: ''
        },
        {
          key: 'highestScore',
          label: '最高分',
          color: '#FF5050',
          value: ''
        },
        {
          key: 'lowestScore',
          label: '最低分',
          color: '#FFBB00',
          value: ''
        },
        {
          key: 'averageScore',
          label: '平均分',
          color: '#4CE47A',
          value: ''
        },
        {
          key: 'medianScore',
          label: '中位数',
          color: '#FFDB7A',
          value: ''
        }
      ],
      skillQuestionCountChart: null, //题目数量饼图
      skillScoreChart: null, //技能得分情况柱状图
      skillAnalysisRadarChart: null, //总体技能分析雷达图
      skillAnalysisList: [],
      skillOverallBarChart: null, //总体技能分析柱状图
      skillList: [], //主技能掌握情况
      subSkillList: [], //子技能掌握情况
      skillPieList: [], //技能评价级别
      isSkillPackUp: true
    }
  },
  methods: {
    init() {
      this.formData = { departmentIds: [], examId: this.$parent.examId, tagIds: [], userIds: [] }
      this.$nextTick(() => {
        this.getBaseInfo()
        this.getQuestionNum()
        this.getScore()
        this.getMainAbilityRate()
        //主技能
        this.getMainSkillScore()
        //评价级别
        this.getEvaluationLevel()
        //子技能
        this.getSubSkillScore()
      })
    },
    getBaseInfo() {
      examResultApi.getAllSimple(this.formData).then(res => {
        this.baseInfo = this.baseInfo.map(item => {
          item.value = res.res[item.key]
          return item
        })
      })
    },
    getQuestionNum() {
      //答题情况饼图
      examProcessApi.getQuestionNum(this.formData).then(res => {
        //答题情况饼图
        let pieOption = JSON.parse(JSON.stringify(pieLoopChart.option))
        pieOption.series[0].center = ['50%', '50%']
        pieOption.series[0].radius = [52, 70]
        pieOption.series[0].label.fontSize = '12px'
        pieOption.series[0].label.width = 90
        pieOption.series[0].labelLine.length2 = 30
        pieOption.series[0].label.overflow = 'truncate'
        pieOption.series[0].data = res.res.map(item => {
          return { value: item.questionCount, name: item.abilityName }
        })
        this.skillQuestionCountChart = this.$echarts.init(this.$refs.skillQuestionCountChart)
        this.skillQuestionCountChart.setOption(pieOption)
      })
    },
    getScore() {
      //得分情况柱状图
      examProcessApi.getScore(this.formData).then(res => {
        let barOption = JSON.parse(JSON.stringify(barStackChart.option))
        barOption.tooltip = {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        }
        barOption.legend = {
          data: ['得分', '失分'],
          top: 0,
          right: 0,
          itemWidth: 16,
          itemHeight: 8,
          itemGap: 20,
          textStyle: {
            fontSize: '14px'
          }
        }
        barOption.grid = { top: 20, bottom: 21, left: 27, right: 7 }
        barOption.dataZoom = [{ type: 'inside' }]
        barOption.xAxis.axisLabel.fontSize = 12
        barOption.xAxis.axisLabel.width = 40
        barOption.xAxis.axisLabel.overflow = 'truncate'
        barOption.xAxis.data = res.res.map(item => {
          return item.abilityName
        })
        barOption.yAxis.axisLabel.fontSize = 12
        barOption.series[0].label.fontSize = 12
        barOption.series[0].data = res.res.map(item => {
          return item.points === 0 ? null : item.points
        })
        delete barOption.series[0].barWidth
        barOption.series[1].label.fontSize = 12
        barOption.series[1].data = res.res.map(item => {
          return item.lostPoints === 0 ? null : item.lostPoints
        })
        delete barOption.series[1].barWidth
        this.skillScoreChart = this.$echarts.init(this.$refs.skillScoreChart)
        this.skillScoreChart.setOption(barOption)
      })
    },
    getMainAbilityRate() {
      //技能整体掌握柱状图
      examProcessApi.getMainAbilityRate(this.formData).then(res => {
        this.skillAnalysisList = res.res
        //技能整体掌握雷达图
        let radarOption = JSON.parse(JSON.stringify(radarChart.option))
        radarOption.legend.right = 23
        radarOption.legend.top = 0
        radarOption.legend.itemWidth = 6
        radarOption.legend.itemHeight = 6
        radarOption.legend.textStyle.fontSize = '12px'
        radarOption.legend.data = [{ icon: 'circle', name: '平均掌握率' }]
        radarOption.radar[0].indicator = res.res.map(item => {
          return {
            name: item.abilityName,
            max: 1
          }
        })
        radarOption.radar[0].radius = this.skillAnalysisList.length > 9 ? 79 : 45
        radarOption.radar[0].center = ['50%', '60%']
        radarOption.radar[0].axisNameGap = 5
        radarOption.radar[0].axisName.fontSize = '12px'
        radarOption.radar[0].axisName.formatter = value => {
          return value.length > 10 ? value.slice(0, 9) + '...' : value
        }
        radarOption.series = [
          {
            type: 'radar',
            symbol: 'circle',
            symbolSize: 5,
            data: [
              {
                value: res.res.map(item => {
                  return item.averageRate
                }),
                name: '平均掌握率',
                itemStyle: {
                  color: '#F16E57', //改变折线点的颜色
                  lineStyle: {
                    color: '#F16E57' //改变折线颜色
                  }
                }
              }
            ]
          }
        ]
        this.$nextTick(() => {
          this.skillAnalysisRadarChart = this.$echarts.init(this.$refs.skillAnalysisRadarChart)
          this.skillAnalysisRadarChart.setOption(radarOption)
          //技能整体掌握柱状图
          let barOption = this.$deepCopy(barHorizontalChart.option)
          barOption.grid = {
            top: 30,
            bottom: 21,
            left: 70,
            right: 70
          }
          barOption.xAxis.axisLabel.fontSize = '12px'
          barOption.yAxis[0].axisLabel.interval = 0
          barOption.yAxis[0].axisLabel.fontSize = '12px'
          barOption.yAxis[0].nameTextStyle.fontSize = '12px'
          barOption.yAxis[0].axisLabel.width = 55
          barOption.yAxis[0].axisLabel.overflow = 'truncate'
          barOption.yAxis[0].data = res.res
            .map(item => {
              return item.abilityName
            })
            .reverse()
          barOption.yAxis[1].axisLabel.fontSize = '12px'
          barOption.yAxis[1].axisLabel.align = 'right'
          barOption.yAxis[1].axisLabel.margin = -3
          barOption.yAxis[1].nameTextStyle.fontSize = '13px'
          barOption.yAxis[1].nameTextStyle.align = 'right'
          barOption.series[0].barWidth = 20
          barOption.yAxis[1].data = res.res
            .map(item => {
              return this.$roundFloat(item.averageRate, 2) + '%'
            })
            .reverse()
          barOption.series[0].data = res.res
            .map(item => {
              return this.$roundFloat(item.averageRate, 2)
            })
            .reverse()
          this.skillOverallBarChart = this.$echarts.init(this.$refs.skillOverallBarChart)
          this.skillOverallBarChart.setOption(barOption)
        })
      })
    },
    getMainSkillScore() {
      examProcessApi.getMainSkillScore(this.formData).then(res => {
        this.skillList = res.res.map(skill => {
          return {
            id: this.$generateUUID(),
            distributionChart: null,
            ...skill,
            pageNum: 0,
            pageSize: 10,
            total: skill.userAbilityRateVOS.length,
            userAbilityList: skill.userAbilityRateVOS.filter((item, index) => index < 10)
          }
        })
        this.$nextTick(() => {
          this.skillList = this.skillList.map(skill => {
            //技能详细掌握雷达图
            let barOption = JSON.parse(JSON.stringify(barLineChart.option))
            barOption.grid = {
              top: 40,
              bottom: 20,
              left: 30,
              right: 60
            }
            barOption.legend.data = ['人数', '比率']
            barOption.legend.textStyle.fontSize = '12px'
            barOption.legend.itemWidth = 16
            barOption.legend.itemHeight = 8
            barOption.legend.right = 60
            barOption.legend.itemGap = 25
            barOption.xAxis.axisLabel.fontSize = '12px'
            barOption.yAxis[0].axisLabel.fontSize = '12px'
            barOption.yAxis[1].axisLabel.fontSize = '12px'
            barOption.xAxis.data = skill.statisticsVOS.map(item => {
              return item.space
            })
            barOption.yAxis[1].axisLabel.formatter = (value, index) => {
              return this.$roundFloat(value, 0) + '%'
            }
            barOption.series[0].data = skill.statisticsVOS.map(item => {
              return item.num
            })
            barOption.series[1].data = skill.statisticsVOS.map(item => {
              return item.frequency
            })
            //技能分布数据图
            skill.distributionChart = this.$echarts.init(this.$refs[`distributionChart-${skill.id}`][0])
            skill.distributionChart.setOption(barOption)
            return skill
          })
        })
      })
    },
    searchSkill({ index, pageNum, pageSize }) {
      this.skillList.forEach((skill, skillIndex) => {
        if (index === skillIndex) {
          skill.pageNum = pageNum
          skill.pageSize = pageSize
          skill.userAbilityList = skill.userAbilityRateVOS.filter(
            (item, index) => index < (skill.pageNum + 1) * skill.pageSize && index >= skill.pageSize * skill.pageNum
          )
        }
      })
    },
    changePage(pageNum, index, pageSize) {
      this.searchSkill({ index: index, pageSize: pageSize, pageNum: pageNum - 1 })
    },
    changePageSize(pageSize, index) {
      this.searchSkill({ index: index, pageSize: pageSize, pageNum: 0 })
    },
    getEvaluationLevel() {
      //评价级别
      examProcessApi.getEvaluationLevel(this.formData).then(res => {
        this.skillPieList = res.res.map(skill => {
          return {
            id: this.$generateUUID(),
            pieChart: null,
            ...skill
          }
        })
        this.$nextTick(() => {
          this.skillPieList = this.skillPieList.map(skill => {
            //技能详细掌握雷达图
            let pieOption = JSON.parse(JSON.stringify(pieChart.option))
            pieOption.series = [
              {
                name: '评级级别',
                type: 'pie',
                radius: [0, 128],
                data: [],
                label: {
                  formatter: '{b}: {d}%',
                  position: 'inside',
                  fontSize: 14,
                  color: '#fff'
                }
              }
            ]
            pieOption.legend.right = '6%'
            pieOption.legend.itemGap = 20
            pieOption.series[0].data = skill.evaluationVOS.map(item => {
              return { value: item.rate, name: item.evaluationName }
            })
            //技能分布数据图
            skill.pieChart = this.$echarts.init(this.$refs[`skillPieChart-${skill.id}`][0])
            skill.pieChart.setOption(pieOption)
            return skill
          })
        })
      })
    },
    changePageSub(pageNum, index, sIndex, pageSize) {
      this.searchSubSkill({ index: index, sIndex: sIndex, pageSize: pageSize, pageNum: pageNum - 1 })
    },
    changePageSizeSub(pageSize, index, sIndex) {
      this.searchSubSkill({ index: index, sIndex: sIndex, pageSize: pageSize, pageNum: 0 })
    },
    searchSubSkill({ index, sIndex, pageNum, pageSize }) {
      this.subSkillList.forEach((item, subIndex) => {
        if (index === subIndex) {
          item.abilityRateVOList.forEach((subSkill, q) => {
            if (sIndex === q) {
              subSkill.pageNum = pageNum
              subSkill.pageSize = pageSize
              subSkill.userAbilitySubList = subSkill.userAbilityRateVOS.filter(
                (item, index) => index < (subSkill.pageNum + 1) * subSkill.pageSize && index >= subSkill.pageSize * subSkill.pageNum
              )
            }
          })
        }
      })
    },
    getSubSkillScore() {
      //子技能
      examProcessApi.getSubSkillScore(this.formData).then(res => {
        this.subSkillList = res.res
          .filter(skill => {
            return skill.hasOwnProperty('abilityRateVOList')
          })
          .map(skill => {
            skill.abilityRateVOList = skill.abilityRateVOList.map(item => {
              return {
                id: this.$generateUUID(),
                distributionChart: null,
                ...item,
                pageNum: 0,
                pageSize: 10,
                total: item.userAbilityRateVOS.length,
                userAbilitySubList: item.userAbilityRateVOS.filter((item, index) => index < 10)
              }
            })
            return {
              id: this.$generateUUID(),
              ...skill
            }
          })
        this.$nextTick(() => {
          this.subSkillList = this.subSkillList.map(skill => {
            //子技能详细分析
            skill.abilityRateVOList = skill.abilityRateVOList.map(subSkill => {
              let barOption = JSON.parse(JSON.stringify(barLineChart.option))
              barOption.grid = {
                top: 40,
                bottom: 20,
                left: 30,
                right: 60
              }
              barOption.legend.data = ['人数', '比率']
              barOption.legend.textStyle.fontSize = '12px'
              barOption.legend.itemWidth = 16
              barOption.legend.itemHeight = 8
              barOption.legend.itemGap = 25
              barOption.legend.right = 60
              barOption.xAxis.axisLabel.fontSize = '12px'
              barOption.yAxis[0].axisLabel.fontSize = '12px'
              barOption.yAxis[1].axisLabel.fontSize = '12px'
              barOption.xAxis.data = subSkill.statisticsVOS.map(item => {
                return item.space
              })
              barOption.yAxis[1].axisLabel.formatter = (value, index) => {
                return this.$roundFloat(value, 0) + '%'
              }
              barOption.series[0].data = subSkill.statisticsVOS.map(item => {
                return item.num
              })
              barOption.series[1].data = subSkill.statisticsVOS.map(item => {
                return item.frequency
              })
              //技能分布数据图
              subSkill.distributionChart = this.$echarts.init(this.$refs[`distributionChart-${subSkill.id}`][0])
              subSkill.distributionChart.setOption(barOption)
              return subSkill
            })
            return skill
          })
        })
      })
    },
    exportPdf() {
      pdfD2(document.getElementById('examReport'), '考试结果分析报告.pdf')
        .then(() => {
          this.$message.success('导出成功!')
        })
        .catch(() => {
          this.$message.error('导出失败!')
        })
        .finally(() => {
          this.$parent.loading = false
        })
    },
    backTop() {
      document.querySelector('.skill-report-dialog').scrollTop = 0
    }
  }
}
</script>

<style lang="less" scoped>
.result-container {
  width: 100%;
  margin-top: 20px;
  padding: 10px;
  background-color: #fff;
  .block {
    width: 100%;
    padding: 10px;
    text-align: left;
    &.shadow {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    }
    p {
      font-size: @default;
      font-weight: bold;
      color: #333333;
    }
    li {
      .flexStyle(flex, space-between);
      height: 32px;
      padding: 0 5px;
      border-bottom: 1px solid #f0f0f0;
      font-size: 12px;
      .label {
        .flexStyle(flex, flex-start);
      }
      .dot {
        display: inline-block;
        width: 4px;
        height: 4px;
        margin-right: 5px;
        border-radius: 50%;
      }
    }
    .yt-table {
      ::v-deep .el-table__header thead th {
        height: 36px;
      }
      ::v-deep .el-table__row {
        height: 36px;
      }
    }
    &.list {
      ::v-deep .el-table th:first-child .cell {
        padding-left: 40px;
      }
      ::v-deep .el-table td:first-child .cell {
        padding-left: 40px;
      }
    }

    ::v-deep .el-table td,
    .el-table th {
      padding: 6px 0;
    }
  }
  .top {
    .flexStyle(flex, flex-start, flex-start);
    height: 220px;
    .block {
      height: 100%;
    }
    .pie-container {
      margin: 0 10px;
      flex-shrink: 0;
    }
    //echarts图
    .chart {
      width: 100%;
      height: 185px;
    }
  }
  .analysis-container {
    .flexStyle(flex, center, flex-start);
    padding: 20px 0 10px;
    .item {
      width: 33%;
      &.has-border {
        position: relative;
        &:after {
          content: '';
          width: 1px;
          height: calc(100% - 75px);
          background-color: #e2e4e8;
          position: absolute;
          top: 40px;
          right: 0;
        }
      }
    }
  }
  .skill-block-wrapper {
    height: 100%;
    padding: 20px;
    margin-top: 10px;
    p {
      font-size: 14px;
      font-weight: 400;
      margin-left: 10px;
      margin-bottom: 10px;
    }
    h3 {
      padding-left: 10px;
      margin-bottom: 10px;
      font-size: @medium;
      color: #448bff;
      position: relative;
      &:before {
        content: '';
        width: 3px;
        height: 12px;
        background: #448bff;
        position: absolute;
        left: 0;
        border-radius: 10px;
      }
    }
    .skill-block {
      .flexStyle(flex, space-between);
    }
  }
  .skill-pie-list {
    .flexStyle(flex, space-between);
    flex-wrap: wrap;
    .skill-pie-item {
      width: 50%;
      height: 355px;
      margin-top: 30px;
      .el-divider {
        background-color: #e2e4e8;
        width: 75%;
        margin: 29px auto 5px auto;
      }
      &.has-border {
        position: relative;
        &:after {
          content: '';
          width: 1px;
          height: 250px;
          background-color: #e2e4e8;
          position: absolute;
          left: 0;
          top: 35px;
          margin: auto 0;
        }
      }
      .skill-pie-title {
        .flexStyle(flex, center);
        font-size: 14px;
        font-weight: bold;
        color: #333;
      }
      .skillPieChart {
        width: 100%;
        height: 315px;
      }
    }
  }
  .pack-up {
    width: 53px;
    height: 25px;
    line-height: 25px;
    background: #f7f7f7;
    border-radius: 13px;
    border: 1px solid #e6e6e6;
    cursor: pointer;
    .flexStyle(flex, flex-start, center);
    .text {
      margin-left: 8px;
      font-size: @small;
      width: 24px !important;
      color: #888888;
      font-weight: none !important;
      user-select: none; //不被选中
    }
    svg {
      width: 9px;
      height: 14px;
      margin-left: 4px;
      margin-top: 14px;
      margin-right: 6px;
      transition: all 0.2s ease-in-out;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    .active {
      -webkit-transform: translateY(-50%) rotate(180deg);
      transform: translateY(-50%) rotate(180deg);
    }
  }
}
.description {
  color: #9b9b9b;
  font-size: 14px;
  font-weight: 400;
}
</style>
