<template>
  <!--    编辑考试信息-->
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb ref="breadcrumb" class="yt-breadcrumb">
        <el-breadcrumb-item>考试管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="'/manage/exam/list'">考试列表</el-breadcrumb-item>
        <el-breadcrumb-item>考试信息</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content-wrapper">
      <div class="step-style">
        <el-tabs v-model="step" @tab-click="handleClick">
          <el-tab-pane label="考试设置" name="0" />
          <el-tab-pane label="考题设置" name="1" />
          <el-tab-pane label="考生设置" name="2" />
          <el-tab-pane v-show="status !== 4" label="考试结果" name="3" />
        </el-tabs>
      </div>
      <div class="content-step--style" :style="{ height: step === '3' ? 'calc(100% - 50px)' : 'calc(100% - 138px)' }">
        <component :is="stepComponents[step]" ref="child" :examId="examId" :paperId="paperId" :bankId="bankId" @savePaper="savePaper"></component>
      </div>
      <div class="content-footer yt-flex-layout flexCenter" v-show="step !== '3'">
        <el-button class="el-button--default btn" @click="$router.push('/manage/exam/list')">返回</el-button>
        <el-button type="primary" class="button" :loading="saveLoading" @click="saveSetting(step)">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import FirstStep from '@components/manage/exam/exam/newPaper/FirstStep'
import EditorExam from '@components/manage/exam/exam/newPaper/EditorExam'
import InternalStaff from '@components/manage/exam/exam/newPaper/InternalStaff'
import ExamResult from '@components/manage/exam/list/ExamResult'
import RandomDialog from '@components/manage/exam/paper/RandomDialog'
import SelectPaperMethods from '@components/manage/exam/exam/newPaper/SelectPaperMethods'
import newExamManageApi from '@api/newExamManage'
import paperApi from '@api/paper'
export default {
  name: 'SettingExam',
  components: { FirstStep, SelectPaperMethods, InternalStaff, ExamResult, EditorExam, RandomDialog },
  data() {
    return {
      saveLoading: false,
      paperType: 0,
      examId: null,
      paperId: null,
      bankId: null,
      external: null, // 是否外部招聘
      status: 0, //考试状态
      step: 0,
      stepComponents: ['FirstStep', 'EditorExam', 'InternalStaff', 'ExamResult']
    }
  },
  created() {
    let params = this.$handleParams('settingExamData')
    this.examId = params.examId
    this.paperId = params.paperId
    this.status = Number(params.status) || 0
    this.step = params.stepIndex
    this.paperType = params.paperType
    if (params.paperType === 4) {
      this.stepComponents[1] = 'RandomDialog'
    }
    this.$nextTick(() => {
      this.getExamInfo()
    })
    if (this.step === '1') {
      if (params.paperType === 4) {
        this.$nextTick(() => {
          this.getPostRandom()
        })
      }
    } else if (this.step === '2') {
      this.$nextTick(() => {
        this.$refs.child.determineTheCategory()
      })
    }
  },
  methods: {
    getExamInfo() {
      // 获取考试和试卷信息
      newExamManageApi.examAllInfo(this.examId).then(res => {
        this.paperId = res.res.paperId
        this.external = res.res.external
        if (this.step === '0') {
          // 第一步时填充表单
          this.$refs.child.setForm(res.res)
        }
      })
    },
    getPostRandom() {
      // 获取随机试卷规则
      paperApi.getPostRandom(this.paperId).then(res => {
        this.bankId = res.res.paperBankId
        this.$refs.child.editorRandomPaper(res.res)
      })
    },
    saveSetting(step) {
      // 保存设置
      this.saveLoading = true
      switch (step) {
        case '0': {
          // 保存考试设置
          this.$refs.child.submit(true, true)
          return
        }
        case '1': {
          // 保存考试试卷设置
          if (this.paperType === 4) {
            this.$refs.child.define()
          } else {
            // 及时保存 不需要再调用方法
            this.$router.push('/manage/exam/list')
          }
          return
        }
        case '2': {
          if (this.external) {
            this.$refs.child.updateOutsiderData()
          }
          this.$router.push('/manage/exam/list')
          return
        }
        default:
          return
      }
    },
    handleClick(e) {
      if (e.name === '0') {
        // 获取考试信息
        this.$nextTick(() => {
          this.getExamInfo()
        })
      } else if (e.name === '1') {
        if (this.paperType === 4) {
          this.$nextTick(() => {
            this.getPostRandom()
          })
        }
      } else if (e.name === '2') {
        this.$nextTick(() => {
          this.$refs.child.determineTheCategory()
        })
      } else if (e.name === '3') {
        this.$nextTick(() => {
          this.$refs.child.getPaperResult(this.examId)
        })
      }
      this.step = e.name
    },
    //保存试卷
    savePaper(questionPoints = [], callback) {
      this.saveLoading = true
      let payload = {
        paperName: this.name,
        examId: this.examId,
        paperId: this.paperId,
        questionPoints: questionPoints
      }
      paperApi
        .updatePaper(payload)
        .then(res => {
          if (res.code === 0) {
            this.paperId = res.res
          }
        })
        .finally(() => {
          callback()
          this.saveLoading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
.yt-main {
  padding-top: 0;
}
.content-wrapper {
  padding: 10px;
  height: 100%;
  .content-footer {
    background: #fff;
    margin-top: 10px;
    height: 78px;
    .btn {
      .wh(120px, 38px);
    }
  }
}
.step-style {
  padding-left: 40px;
  background: #fff;
  border-radius: 6px;
  height: 40px;
  margin-bottom: 10px;
  ::v-deep {
    .el-tabs__item.is-active,
    .el-tabs__item:hover {
      color: #448bff;
    }
    .el-tabs__active-bar {
      background: #448bff;
    }
  }
}
.content-step--style {
  width: 100%;
  background: #fff;
  overflow-y: auto;
  ::v-deep .el-table {
    overflow-y: scroll;
  }
  .yt-main {
    width: 100% !important;
  }
}

::v-deep .el-tabs__nav-wrap::after {
  height: 0 !important;
}
</style>
