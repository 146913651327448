let option = {
  legend: {
    orient: 'vertical',
    top: 40,
    right: 45,
    itemWidth: 10,
    itemHeight: 10,
    icon: 'circle',
    textStyle: {
      fontSize: 14
    }
  },
  series: [
    {
      name: '评级级别',
      type: 'pie',
      center: [138, '50%'],
      radius: [0, 128],
      data: [],
      label: {
        formatter: '{b}: {d}%',
        position: 'inside',
        fontSize: 14,
        color: '#fff'
      }
    }
  ]
}

export default { option }
