<template>
  <div>
    <el-form class="yt-form" ref="paperForm" label-width="105px" :model="firstStep" :rules="firstRules">
      <el-row>
        <el-col :span="24">
          <el-form-item label="考试用途" prop="external">
            <!--     已存在考试id  不能更改考试用途   -->
            <el-select
              v-model="firstStep.external"
              :disabled="examId !== null"
              clearable
              size="small"
              style="width: 100%"
              placeholder="请选择考试用途"
            >
              <el-option :value="0" label="内部评测" />
              <el-option :value="1" label="外部招聘" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="考试名称" prop="name">
            <el-input v-model.trim="firstStep.name" style="width: 100%" :maxlength="20" show-word-limit clearable placeholder="请填写考试名称" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="岗位" prop="postId">
            <el-select v-model.trim="firstStep.postId" style="width: 100%" :clearable="false" placeholder="请选择岗位">
              <el-option v-for="(p, index) in postList" :key="index" :label="p.name" :value="p.id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="考试时长" prop="limitTime">
            <el-input v-model.trim="firstStep.limitTime" @change="judgeTime" placeholder="请输入考试时长">
              <div slot="suffix" style="padding-right: 3px">分钟</div>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="通过比例" prop="passRate">
            <el-input v-model.trim="firstStep.passRate" style="width: 100%" @change="judgePassR" clearable placeholder="请输入通过比例">
              <div slot="suffix" style="padding-right: 3px">(0-1)</div>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开始时间">
            <el-date-picker
              v-model.trim="firstStep.startTime"
              type="datetime"
              format="yyyy-MM-dd HH:mm"
              :clearable="false"
              prefix-icon="el-icon-date"
              @blur="firstStep.endTime = null"
              :picker-options="{ disabledDate: checkStartDate }"
              placeholder="请输入开始时间"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="结束时间">
            <el-date-picker
              v-model.trim="firstStep.endTime"
              :picker-options="{ disabledDate: checkStartDate }"
              class="form-input time-style"
              type="datetime"
              format="yyyy-MM-dd HH:mm"
              :clearable="false"
              @blur="validateEndTime"
              prefix-icon="el-icon-date"
              placeholder="请输入结束时间"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24" class="border">
          <p class="title">用户端配置</p>
        </el-col>
        <el-col :span="12">
          <el-form-item label="考试详情查看">
            <el-radio-group v-model="firstStep.isView">
              <el-radio :label="0">可查看</el-radio>
              <el-radio :label="1">不可查看</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="考试报告下载">
            <el-radio-group v-model="firstStep.isDownload">
              <el-radio :label="0">可下载</el-radio>
              <el-radio :label="1">不可下载</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="考试分数查看">
            <el-radio-group v-model="firstStep.viewScore">
              <el-radio :label="true">可查看</el-radio>
              <el-radio :label="false">不可查看</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="是否可以重考">
            <el-radio-group :disabled="firstStep.external === 1" v-model="firstStep.repeatState">
              <el-radio :label="1">可重考</el-radio>
              <el-radio :label="0">不可重考</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col v-if="firstStep.repeatState" :span="12">
          <el-form-item label="重考次数" prop="repeatNum">
            <el-input
              v-model="firstStep.repeatNum"
              size="small"
              oninput="value=value.replace(/[^0-9.]/g,'')"
              maxlength="3"
              @change="validateNum"
              placeholder="设置重考次数, 输入0可无限次重考"
              style="width: 100%"
            >
              <template slot="suffix">次</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="切屏次数" :rules="[{ required: true }]">
            <el-input-number v-model="firstStep.blurCount" :controls="false" :min="0" :max="999"></el-input-number>
            <el-tooltip class="item" effect="dark" content="设置为0时不限制切屏次数" placement="top">
              <YTIcon :href="'#icon-tishi2'" style="color: #999999; font-size: 16px; margin-left: 4px"></YTIcon>
            </el-tooltip>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="考试简介" v-show="firstStep.external === 1" label-width="100px">
            <el-input
              type="textarea"
              class="form-input form-textarea"
              resize="none"
              :autosize="{ minRows: 4, maxRows: 6 }"
              :maxlength="250"
              v-model="firstStep.description"
              placeholder="请输入考试的简介..."
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="考前须知" v-show="firstStep.external === 1" label-width="100px">
            <el-input
              type="textarea"
              class="form-input form-textarea"
              resize="none"
              :autosize="{ minRows: 4, maxRows: 6 }"
              :maxlength="300"
              v-model="firstStep.messageBeforeExam"
              placeholder="请输入考前须知..."
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item v-show="firstStep.external === 1" label="考后须知" label-width="100px">
            <el-input
              type="textarea"
              class="form-input form-textarea"
              resize="none"
              :autosize="{ minRows: 4, maxRows: 6 }"
              :maxlength="300"
              v-model="firstStep.messageAfterExam"
              placeholder="请输入考后须知..."
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <ConfirmDialog ref="confirmDialog" :title="'提示'" @on-ok="generateExamId(false)">
      <div>
        <p style="font-size:18px;"><YTIcon :href="'#icon-jingshi'" style="font-size: 17px;margin-right: 5px" />已有同名的考试，确定要新增吗？</p>
        <p style="color: #666666">如需要增加考试人员，直接在已有的考试中，点击考生即可</p>
      </div>
    </ConfirmDialog>
  </div>
</template>

<script>
import newExamManageApi from '@api/newExamManage'
import postApi from '@api/post'
import YTIcon from '@components/common/YTIcon'
import examApi from '@/api/exam'
import ConfirmDialog from '@components/common/dialog/ConfirmDialog'
export default {
  name: 'FirstStep',
  components: { ConfirmDialog, YTIcon },
  props: ['examId'],
  data() {
    return {
      firstStep: {
        repeatState: 0, // 0 不可以重考
        repeatNum: null, // 重考时设置次数  不可重考是null  可以重考最低是1
        external: 0,
        isView: 1,
        isDownload: 1,
        viewScore: true,
        name: null,
        examId: null,
        description: null,
        messageBeforeExam: null,
        messageAfterExam: null,
        limitTime: 120,
        passRate: 0.6,
        passCount: null,
        startTime: null,
        endTime: null,
        postId: null,
        blurCount: 10
      },
      postList: [],
      firstRules: {
        external: { required: true, message: '请选择考试用途', trigger: 'blur' },
        name: [{ required: true, message: '测评名称不能为空', trigger: 'blur' }],
        limitTime: [{ required: true, message: '考试时长不能为空', trigger: 'blur' }],
        repeatNum: [{ required: true, message: '重考次数不能为空', trigger: 'blur' }],
        passRate: [{ required: true, message: '请设置通过比列', trigger: 'blur' }],
        postId: [{ required: true, message: '请选择岗位', trigger: 'change' }]
      }
    }
  },
  created() {
    this.getPosts()
  },
  methods: {
    getPosts() {
      postApi.getAllPosts().then(res => {
        this.postList = res.res
      })
    },
    setForm(data) {
      this.firstStep = {
        ...this.firstStep,
        ...data,
        external: data.external ? data.external : 0,
        passRate: data.passScore,
        postId: data.postIds && data.postIds[0] ? data.postIds[0] : null
      }
    },
    validateNum(num) {
      if (Number(num) < 0 || num === '') return (this.firstStep.repeatNum = 1)
      if (parseFloat(num).toString() === 'NaN') {
        this.firstStep.repeatNum = null
        return
      }
      this.firstStep.repeatNum = parseInt(num) // 取整
    },
    checkStartDate(date) {
      // 考试开始时间
      return Date.now() > date.getTime() + 24 * 60 * 60 * 1000
    },
    validateEndTime() {
      // 考试结束时间
      if (this.firstStep.endTime && this.firstStep.endTime <= this.firstStep.startTime) {
        this.firstStep.endTime = null
        return this.$message.warning('结束时间请大于开始时间')
      }
    },
    judgePassR(e) {
      // 校验通过比列
      let a = Number(e)
      if (isNaN(a) || a <= 0 || a > 1) return (this.firstStep.passRate = 0.6)
    },
    judgeTime(e) {
      // 校验时长 不大于999
      let a = Number(e)
      if (isNaN(a) || a <= 0 || a > 999) return (this.firstStep.limitTime = 90)
    },
    async submit(isUpdate = false, isSave = false) {
      // 创建时查重, 修改时不查
      let valid = await this.$refs['paperForm'].validate()
      if (valid) {
        if (isUpdate) {
          this.generateExamId(isSave)
        } else {
          let repeatStatus = await examApi.queryExist(this.firstStep.name)
          if (repeatStatus.res) {
            this.$refs.confirmDialog.open()
          } else {
            this.generateExamId(isSave)
          }
        }
      }
    },
    generateExamId(isSave = false) {
      this.firstStep.examId = this.examId
      let payload = {
        ...this.firstStep,
        postIds: [this.firstStep.postId]
      }
      newExamManageApi.generateExamId(payload).then(res => {
        if (res.code === 0) {
          this.$message.success('考试信息保存成功')
          if (isSave) {
            this.$router.push('/manage/exam/list')
          } else {
            this.$emit('goToNextStep', { examId: res.res, examName: payload.name })
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.yt-form {
  padding: 20px 20px 10px;
  max-width: 1024px;
  margin: 0 auto;
  .el-col {
    margin-bottom: 20px;
  }
  ::v-deep .el-input {
    width: 100%;
  }
  ::v-deep .el-input-number {
    .el-input__inner {
      text-align: left;
    }
  }
  .border {
    position: relative;
    .title {
      line-height: 40px;
      font-size: @medium;
      margin-left: 20px;
      &:before {
        content: '';
        width: 4px;
        height: 20px;
        position: absolute;
        left: 0;
        top: 10px;
        background-color: #2878ff;
        border-radius: 1px;
      }
    }
  }
}
//.input-number {
//  ::v-deep .el-input__inner {
//    height: 38px;
//    width: 163px;
//    padding-left: 39px;
//  }
//  ::v-deep .el-input {
//    width: 163px;
//  }
//  ::v-deep .input-number {
//    width: 163px;
//    line-height: 38px;
//  }
//  ::v-deep .el-input-number__decrease {
//    width: 32px;
//    height: 36px;
//  }
//  ::v-deep .el-input-number__increase {
//    width: 32px;
//    height: 36px;
//  }
//}
//.yt-flex-layout {
//  justify-content: space-between;
//}

//.form-input {
//  width: 100%;
//  border-radius: 2px;
//  border-color: #d7d7d7;
//  ::v-deep .el-input {
//    width: 100% !important;
//  }
//}
//.form-textarea:focus {
//  border-color: #4579ea;
//}
//.result_div {
//  height: 60px;
//  justify-content: flex-start;
//  align-items: flex-start;
//  .font(14px, Regular, #000);
//
//  .yt-radio-group {
//    padding-left: 20px;
//    width: 50%;
//    height: 41px;
//    justify-content: flex-start;
//    align-items: center;
//
//    span {
//      line-height: 48px;
//      margin-right: 12px;
//    }
//  }
//
//  .div2 {
//    padding-left: 38px;
//  }
//}
//::v-deep {
//  .time-style {
//    .el-input__inner {
//      padding-left: 10px !important;
//    }
//    .el-input__prefix {
//      right: 5px !important;
//      left: auto !important;
//    }
//  }
//  .el-form-item__content {
//    width: 100%;
//    text-align: left;
//  }
//  .el-form-item__label {
//    white-space: nowrap;
//    line-height: 39px;
//  }
//  .el-form-item {
//    display: flex;
//    align-content: center;
//    margin-bottom: 40px !important;
//  }
//  .el-form-item__content {
//    margin-left: 0 !important;
//  }
//  .el-input__suffix-inner {
//    display: flex;
//    justify-content: center;
//    align-items: center;
//    i {
//      line-height: 38px;
//    }
//  }
//  .el-input__suffix-inner {
//    height: 100%;
//  }
//  .el-input__inner,
//  .el-textarea__inner {
//    font-size: 14px;
//    &::placeholder {
//      .font(14px, Regular, #999);
//    }
//  }
//}
</style>
