<template>
  <div style="padding: 0 20px">
    <div class="has-border">
      <p><span class="required">*</span><b>设置随机出卷</b></p>
      <el-row>
        <el-col :span="15">
          <span style="margin-right: 20px">随机试卷名称</span>
          <el-input style="width: 350px" v-model="randomData.paperName" :maxlength="20" show-word-limit placeholder="请设置试卷名称" />
        </el-col>
        <el-col :span="9">
          <span style="margin-right: 20px">试卷分数</span>
          <el-input-number v-model="randomData.totalScore" controls-position="right" :precision="0" :min="0" :max="999" />
        </el-col>
      </el-row>
    </div>
    <div class="has-border">
      <div style="display: flex; justify-content: space-between">
        <p><span class="required">*</span><b>选择随机题库</b></p>
        <el-button type="info" class="empty-button" @click="emptySelect" plain>清空</el-button>
      </div>
      <el-select
        class="random-name"
        style="width: 100%"
        v-model="randomData.selectQBanks"
        multiple
        filterable
        default-first-option
        placeholder="请选择题库"
      >
        <el-option v-for="(b, bIndex) in questionBankList" :key="bIndex" :label="b.name" :value="b.questionBankId" />
      </el-select>
    </div>
    <div class="has-border">
      <p><span class="required">*</span><b>选择岗位模型</b></p>
      <el-select class="random-name" style="width: 100%" v-model="post" placeholder="请选择" @change="judgePostCode()">
        <el-option v-for="item in postData" :key="item.id" :label="item.name" :value="item.id"> </el-option>
      </el-select>
    </div>
    <div v-show="codeFlag" class="has-border">
      <p><span class="required"></span><b>可设置代码题数量</b></p>
      <el-row>
        <el-col :span="12" v-for="(a, index) in randomData.questionsNum" :key="index">
          <span style="margin-right: 20px">代码题</span>
          <el-input-number v-model="codeQuestionCount" :min="0" label="描述文字"></el-input-number>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import postApi from '@api/post'
import newPostApi from '@api/newPost'
import questionBankApi from '@api/questionBank'
import paper from '@api/paper'

export default {
  name: 'RandomDialog',
  props: ['bankId', 'examId', 'paperId'],
  data() {
    return {
      codeFlag: false, // 代码题设置
      codeQuestionCount: 0, // 代码题数量
      post: '', // 岗位
      postData: '', // 岗位选项
      randomData: {
        paperName: null,
        totalScore: null,
        codeNum: 0,
        selectQBanks: [],
        questionsNum: [
          {
            title: 5,
            key: 'codeQ',
            value: null
          }
        ]
      },
      questionBankList: []
    }
  },
  mounted() {
    this.getPost()
    this.getBank()
  },
  methods: {
    getPost() {
      //获取岗位
      postApi.getPostByType(true).then(res => {
        this.postData = res.res
      })
    },
    getBank() {
      //获取题库
      questionBankApi.searchSimpleInfoByPermission().then(res => {
        this.questionBankList = res.res
      })
    },
    editorRandomPaper(payload) {
      // 获取编辑信息
      this.post = payload.postId
      this.randomData.paperName = payload.name
      this.randomData.totalScore = payload.totalScore
      this.randomData.selectQBanks = payload.questionBankIds
      this.judgePostCode()
      this.codeQuestionCount = payload.codeQuestionCount
    },
    emptySelect() {
      this.randomData.selectQBanks = []
    },
    //判断是否有代码题
    judgePostCode() {
      this.codeQuestionCount = 0
      newPostApi.judgeCodeQuestion(this.post).then(res => {
        this.codeFlag = res.res
      })
    },
    define() {
      // 校验必填项
      if (!this.randomData.paperName) {
        this.$emit('closeLoading')
        this.$message.warning('随机试卷的名称不可为空！')
        return
      }
      if (!this.randomData.totalScore) {
        this.$emit('closeLoading')
        this.$message.warning('随机试卷的总分必须大于0')
        return
      }
      if (this.randomData.selectQBanks.length <= 0) {
        this.$emit('closeLoading')
        this.$message.warning('请选择题库')
        return
      }
      if (!this.post) {
        this.$emit('closeLoading')
        this.$message.warning('请选择岗位模型')
        return
      }
      // 生成随机试卷
      let payload = {
        codeQuestionCount: this.codeQuestionCount,
        name: this.randomData.paperName,
        examId: this.examId,
        paperId: this.paperId,
        paperBankId: this.bankId,
        postId: this.post,
        questionBankIds: this.randomData.selectQBanks,
        totalScore: this.randomData.totalScore
      }
      paper
        .createPostRandom(payload)
        .then(res => {
          if (res.code === 0) {
            this.$message.success('岗位模型试卷规则生成成功')
            this.$emit('goToNextStep', res.res)
          } else {
            this.loading = false
            this.$emit('closeLoading')
          }
        })
        .catch(err => {
          this.$emit('closeLoading')
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
span {
  text-align: left;
}
.empty-button {
  width: 48px;
  height: 20px;
  background: #f0f0f0;
  padding: initial;
  opacity: 1;
  border-radius: 10px !important;
}
.has-border {
  border-bottom: 1px solid #e2e4e8;
  padding: 20px 0;
  p {
    .font(14px, bold, #000);
    margin-bottom: 20px;
  }
}
.required {
  .font(12px, 500, #f43b3b);
}
.random-modal {
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  padding: 20px;
  .number-error {
    border: 1px solid #ff1c00;
  }

  .random-name {
    //margin: 0 20px;
    //padding: 0 20px;
    margin-top: 20px;
    justify-content: space-between;
  }
  .random-type {
    padding: 0 20px;
    margin-top: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
    .el-number--input {
      width: 190px;
      &:not(:nth-child(3)),
      &:not(:nth-child(6)) {
        margin-right: 0 !important;
      }
      ::v-deep .el-input__inner:focus {
        border-color: #e0e0e0;
      }
    }
    .type-only {
      padding-right: 1px;
      //margin-bottom: 20px;
    }
    .type-span {
      margin-right: 20px;
      width: 49px;
      justify-content: flex-end;
      align-items: center;
    }
  }
  .flexD {
    flex-direction: column;
    margin-bottom: 20px;
  }
}

@media screen and (max-height: 936px) {
  ::v-deep .source {
    margin-top: 4vh !important;
  }
}
.dialog-footer {
  .flexStyle(flex);
  margin: 0 60px;
}
::v-deep .el-tag.el-tag--info {
  &:hover {
    background-color: #edf4ff !important;
    color: #448bff;
  }
}
::v-deep .el-select .el-tag__close.el-icon-close {
  &:hover {
    background-color: #448bff;
  }
}
</style>
