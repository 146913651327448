<template>
  <div>
    <h3>请选择出卷方式</h3>
    <ul>
      <li v-for="(item, index) in methodsList" :key="index" :class="{ active: activeIndex === index }" @click="selectRowInfo(index)">
        <img class="icon" :src="item.icon" alt="" />
        <div>
          <b>{{ item.name }}</b>
          <p>{{ item.description }}</p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import Jy1 from '@/assets/paperPage/选择试卷icon.png'
import Jy2 from '@/assets/paperPage/手动出卷icon.png'
import Jy3 from '@/assets/paperPage/自动出卷icon.png'
import Jy4 from '@/assets/paperPage/随即出卷icon.png'
export default {
  name: 'SelectPaperMethods',
  data() {
    return {
      methodsList: [
        {
          icon: Jy1,
          name: '选择试卷',
          description: '您可以选择已经出好的试卷，在其基础上进行修改，完成试卷制作'
        },
        {
          icon: Jy2,
          name: '手动出卷',
          description: '您可以手动添加题目，并对其进行修改，支持技能、知识点、题型等条件进行筛选'
        },
        {
          icon: Jy3,
          name: '自动出卷',
          description: '您可以选择技能，并输入各题型的题数，系统自动为您生成一份试卷'
        },
        {
          icon: Jy4,
          name: '随机出卷',
          description: '您可以选择题库和题目数量，系统自动生成试卷，每位考生试卷均不相同'
        }
      ],
      activeIndex: 0 //选中的出卷方式
    }
  },
  methods: {
    selectRowInfo(index) {
      this.activeIndex = index
      this.$emit('goToNextStep', index)
    }
  }
}
</script>

<style lang="less" scoped>
h3 {
  font-size: 18px;
  text-align: center;
  margin: 30px 0;
}
ul {
  .flexStyle(flex);
  flex-wrap: wrap;
}
li {
  display: flex;
  flex-shrink: 0;
  min-width: 398px;
  width: calc(50% - 180px);
  height: 118px;
  margin: 10px;
  padding: 20px;
  border: 2px solid transparent;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  &:hover {
    border-color: #448bff;
    box-shadow: 0 3px 6px rgba(60, 100, 209, 0.5);
  }
  &.active {
    border-color: #448bff;
    box-shadow: 0 3px 6px rgba(60, 100, 209, 0.5);
  }
  .icon {
    width: 44px;
    height: 44px;
    margin-right: 12px;
    color: #448bff;
    flex-shrink: 0;
  }
  b {
    margin-bottom: 6px;
    font-size: 14px;
    line-height: 22px;
  }
  p {
    color: #666666;
    line-height: 22px;
  }
}
</style>
