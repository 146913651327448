import config from '../config/config'
import { axios } from './index'

const examProcessUrl = config.baseUrl + '/exam/api/v1/exam'

export default {
  //获取考试总体技能分析接口模拟
  getMainAbilityRate(payload) {
    return axios.post(`${examProcessUrl}/get/main/ability/rate`, payload)
  },
  //获取考试总体技能分析接口模拟
  getSubAbilityRate(payload) {
    return axios.post(`${examProcessUrl}/get/sub/ability/rate`, payload)
  },
  //获取考试技能下题目数量接口模拟
  getQuestionNum(payload) {
    return axios.post(`${examProcessUrl}/get/exam/ability/question/num`, payload)
  },
  //获取考试技能下题目得分失分情况接口模拟
  getScore(payload) {
    return axios.post(`${examProcessUrl}/get/exam/ability/score`, payload)
  },
  //获取考试考生排名接口模拟
  getExamRank(payload) {
    return axios.post(`${examProcessUrl}/get/exam/rank`, payload)
  },
  //获取考试主技能分数分布情况模拟
  getMainSkillScore(payload) {
    return axios.post(`${examProcessUrl}/main/skill/score`, payload)
  },
  //获取考试评价级别接口模拟
  getEvaluationLevel(payload) {
    return axios.post(`${examProcessUrl}/get/exam/ability/evaluation/level`, payload)
  },
  //获取考试子技能分数分布情况模拟
  getSubSkillScore(payload) {
    return axios.post(`${examProcessUrl}/sub/skill/score`, payload)
  },
  //随机出卷获取考试成绩总体分布
  getScoreStatistics(payload, interval) {
    return axios.post(`${examProcessUrl}/score/statistics?interval=${interval}`, payload)
  }
}
