<template>
  <div class="ExternalAdd">
    <div v-if="limitPayload" style="text-align: center;margin-bottom: 20px">
      <span>剩余人次数</span>
      <span class="blue_span" style="margin-left: 10px">{{ limitPayload.unusedCount }}</span>
    </div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="姓名" prop="realName">
        <el-input :maxlength="10" show-word-limit v-model.trim="ruleForm.realName" style="width:350px;"></el-input>
      </el-form-item>
      <el-form-item label="手机号码" prop="phone">
        <el-input v-model.trim="ruleForm.phone" style="width:350px;"></el-input>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input v-model.trim="ruleForm.email" style="width:350px;"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'ExternalAdd',
  props: ['limitPayload'],
  data() {
    return {
      ruleForm: {
        realName: '',
        phone: null,
        email: ''
      },
      rules: {
        realName: [{ required: true, message: '姓名不能为空' }],
        phone: [
          { required: true, message: '手机号码不能为空' },
          {
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: '手机号码格式不对',
            trigger: 'blur'
          }
        ],
        email: [
          {
            required: true,
            message: '邮箱不能为空'
          },
          {
            type: 'email',
            message: '请输入正确格式的邮箱'
          }
        ]
      }
    }
  },
  watch: {},
  mounted() {},
  methods: {
    submitForm() {
      let status = false
      this.$refs['ruleForm'].validate(valid => {
        status = valid
      })
      return status ? this.ruleForm : false
    }
  }
}
</script>

<style lang="less" scoped>
.blue_span {
  color: #448bff;
}
::v-deep .el-form-item__content {
  text-align: left;
}

.number ::v-deep .a input::-webkit-outer-spin-button,
.number ::v-deep .a input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.number ::v-deep .a input[type='number'] {
  -moz-appearance: textfield;
}
</style>
