<template>
  <div style="display: flex; background-color: #F3F4F7; height: 100%" v-loading="questionListLoading" element-loading-text="加载中">
    <div class="yt-container">
      <div class="yt-content">
        <!--全选-->
        <div class="select-all">
          <el-checkbox :indeterminate="isIndeterminate" v-model="isSelectAll" @change="handleCheckAllChange">全选题目</el-checkbox>
          <div style="margin-right: 20px">
            <el-button @click="addQuestionsModal" type="primary" size="small">
              <div>
                <YTIcon @click="addQuestionsModal" :href="'#icon-xinzeng'" />
                <p>增加题目</p>
              </div>
            </el-button>
            <el-button @click="toBatchDelete" size="small" class="btn-del">
              <div>
                <YTIcon @click="toBatchDelete" :href="'#icon-shanchu'" style="fill: #999" />
                <p>批量删除</p>
              </div>
            </el-button>
            <el-button @click="storageQ" size="small" class="btn-del">
              <div>
                <YTIcon @click="storageQ" :href="'#icon-baocun'" style="fill: #999" />
                <p>存为试卷</p>
              </div>
            </el-button>
          </div>
        </div>
        <div class="question-container">
          <div
            class="list"
            :style="{
              overflowY: questionListLoading ? 'hidden' : 'auto'
            }"
          >
            <!--题目卡片-->
            <template>
              <div v-for="(item, index) in questionList">
                <div v-if="item.questionVOS.length > 0">
                  <div class="question-sort">
                    <p>{{ item.name }}</p>
                    <p style="color: #448BFF">
                      {{ item.questionVOS.length }}
                    </p>
                    <p>题</p>
                    <el-input-number
                      style="margin-right: 5px"
                      v-model="item.totalPoints"
                      :controls="false"
                      :min="0.5"
                      :step="0.5"
                      :step-strictly="true"
                      :precision="1"
                      @blur="setBigTopicPoint(item.totalPoints, index)"
                      @keydown.enter.native="setBigTopicPoint(item.totalPoints, index)"
                    />
                    <p>分</p>
                  </div>
                  <div
                    @mouseenter="selectRowId = question.questionId"
                    v-for="(question, qIndex) in questionList[index].questionVOS"
                    :key="qIndex"
                    class="item"
                    :id="'anchor-' + question.questionId"
                  >
                    <el-checkbox class="checkbox" v-model="question.isChecked" @change="handleCheckQuestion($event, question.questionId)" />
                    <div style="width: 100%">
                      <!--题目卡片头部-->
                      <div class="header">
                        <div class="question-type">
                          {{ ytConstant.questionType.getLabel(question.questionDetail.questionType)[0] }}
                        </div>
                      </div>
                      <QuestionCard
                        style="padding: 0 10px 0 20px"
                        :question="{
                          ...question.questionDetail,
                          index: qIndex
                        }"
                        :options="{
                          showSize: showSize,
                          tag: {
                            show: true
                          },
                          knowledge: {
                            show: false
                          },
                          ability: {
                            show: false
                          }
                        }"
                      >
                      </QuestionCard>
                      <!--题目卡片底部-->
                      <div class="footer">
                        <div>
                          <p class="inactive">难度系<span style="letter-spacing: 5px">数:</span>{{ question.questionDetail.difficulty / 10 }}</p>
                          <p>使用次<span style="letter-spacing: 5px">数:</span>{{ question.questionDetail.useCount || 0 }}</p>
                          <p style="display: flex;flex-flow: row">
                            设置分<span style="letter-spacing: 5px">数:</span>
                            <el-input-number
                              :controls="false"
                              :min="0.5"
                              :step="0.5"
                              :step-strictly="true"
                              :precision="1"
                              v-model="question.points"
                              @blur="setSmallTopicPoint(question.questionId, question.points, index)"
                            />
                            <span style="margin-left: 6px">分</span>
                          </p>
                          <!--                          oninput="value=Number(value.replace(/[^\d.]/g,''))"-->
                          <!--                          onkeyup="let v=this.value||'';if(v<1||v===''){this.value=0.5;}else if(v>1&&v.toString().indexOf('.')!==-1){this.value=Number(Math.floor(v));} "-->
                        </div>
                        <div @click="toDelete(question, index)" style="cursor: pointer;">
                          <YTIcon style="margin-right: 5px" @click="toDelete(question, index)" :href="'#icon-yichu'" />
                          移除
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div v-if="!questionList.length" class="empty">
            <YTIcon :href="'#icon-zanwushuju'" />
            <p>点击<span @click="addQuestionsModal">增加题目</span>为试卷添加题目</p>
          </div>
        </div>
      </div>
    </div>
    <div class="right-container" :style="{ width: isPackUp ? '18px' : '300px' }">
      <YTIcon
        :href="'#icon-shouqi'"
        @click="isPackUp = !isPackUp"
        class="pack-up-button"
        :style="{
          transform: isPackUp ? 'rotate(0deg)' : 'rotate(180deg)'
        }"
      />
      <div style="width: 100%; height: 100%" :class="{ 'is-pack-up': isPackUp }">
        <div class="menu">
          <p @click="statistical = !statistical" :class="{ active: statistical }">按题型统计</p>
          <p @click="statistical = !statistical" :class="{ active: !statistical }">按技能统计</p>
        </div>
        <div class="header">
          <span>
            总题量
            <span style="color: #448BFF">{{ totalAmount }}</span>
            题
          </span>
          <span>
            总分
            <span style="color: #448BFF">{{ totalPoints }}</span>
            分
          </span>
        </div>
        <ul class="question-type">
          <li v-for="(q, index) in statistical ? questionList : statisticalList" :key="index">
            <div v-if="q.questionVOS.length > 0">
              <div class="yt-flex-layout" style="justify-content: space-between">
                <div class="yt-flex-layout" style="align-items: center">
                  <div class="type" />
                  <span class="span">{{ q.name }}</span>
                  <template v-if="statistical">
                    <el-input-number
                      v-model="q.totalPoints"
                      :controls="false"
                      :min="0.5"
                      :step="0.5"
                      :step-strictly="true"
                      :precision="1"
                      style="margin: 0 10px"
                      @keydown.enter.native="setBigTopicPoint(q.totalPoints, index)"
                      @blur="setBigTopicPoint(q.totalPoints, index)"
                    />
                    <span>分</span>
                  </template>
                </div>
                <div class="del-svg">
                  <YTIcon @click="delQuestion(q)" :href="'#icon-shanchu'" style="width: 15px;height: 15px;fill: #999" />
                </div>
              </div>
              <div class="question-number yt-flex-layout">
                <div
                  v-for="(qs, sIndex) in q.questionVOS"
                  :key="sIndex"
                  @click="selectorById(qs)"
                  :class="{ selectRow: selectRowId === qs.questionId }"
                  class="number yt-flex-layout flexCenter"
                >
                  {{ sIndex < 9 ? '0' : '' }}{{ sIndex + 1 }}
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <ConfirmDialog ref="batchDeleteDialog" title="批量删除题目" @on-ok="batchDelete">
      <div style="width: 219px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
        确定删除{{ deleteContent }}所有题目吗,删除后不可恢复
      </div>
    </ConfirmDialog>
    <el-dialog
      class="yt-dialog question-choose-dialog"
      title="选择题目"
      :visible.sync="visible"
      width="1080px"
      top="30px"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      :fullscreen="fullscreen"
    >
      <Manual ref="manual" :state="visible" :fullscreen="fullscreen" />
      <div slot="footer" class="footer" style="justify-content: space-between">
        <el-button class="button" type="primary" @click="fullscreen = !fullscreen">
          <YTIcon
            style="color: #FFFFFF;margin-right: 10px;"
            @click="fullscreen = !fullscreen"
            :href="fullscreen ? '#icon-quanpingshouqi1' : '#icon-quanping'"
          />
          <span v-text="fullscreen ? '全屏收起' : '全屏编辑'" />
        </el-button>
        <div>
          <el-button class="button" @click="visible = false">取 消</el-button>
          <el-button class="button" type="primary" @click="addSuccess" :loading="addLoading">确 定</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="storageVisible" class="yt-dialog yt-dialog-default" title="存为试卷">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="125px" class="yt-form">
        <el-form-item label="选择试卷库" prop="paperBankId">
          <el-select filterable v-model="ruleForm.paperBankId" placeholder="请选择试卷库">
            <el-option v-for="(data, index) in libraryList" :key="index" :label="data.name" :value="data.paperBankId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="试卷名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="yt-flex-layout" style="justify-content: center">
        <div>
          <el-button type="primary" @click="determine" :loading="storageLoading">确 定</el-button>
          <el-button @click="storageVisible = false">取 消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import YTIcon from '@/components/common/YTIcon'
import QuestionCard from '@components/common/version2/QuestionCard'
import paper from '@/api/paper'
import newExerciseExamApi from '@api/newExerciseExam'
import Manual from '@/components/manage/exam/exam/newPaper/Manual'
import ConfirmDialog from '@components/common/dialog/ConfirmDialog'
export default {
  name: 'PaperEdit',
  components: {
    YTIcon,
    QuestionCard,
    Manual,
    ConfirmDialog
  },
  props: ['name', 'paperId', 'examId'],
  data() {
    return {
      mode: '', // 判断保存试卷后的回调
      statistical: true, // true 题型, false 技能
      statisticalList: [], // 技能列表
      questionList: [], //已选用的题目列表
      // 右侧
      isPackUp: false, // 收起
      selectRowId: -1, // 选中题目id
      // 存为试卷
      libraryList: [], // 试卷列表
      storageLoading: false, // 防抖
      ruleForm: {
        paperBankId: null,
        name: ''
      }, // 表单
      rules: {
        name: [{ required: true, message: '请输入试卷名称', trigger: 'blur' }],
        paperBankId: [{ required: true, message: '请选择试卷库', trigger: 'change' }]
      }, // 验证
      storageVisible: false, // 弹窗开关
      // 选择题目
      visible: false, // 弹窗开关
      fullscreen: false, // 全屏
      addLoading: false, // 防抖
      // 批量删除
      deleteLoading: false, // 移除防抖
      deleteContent: '',
      delRows: [],
      removeQuestionIds: [],
      questionSelection: [], //被选中题目
      isSelectAll: false, //是否全选
      isIndeterminate: false, //是否开启不确定全选
      questionListLoading: true, //是否加载中
      showSize: 0 //根据屏幕宽度展示标签的个数
    }
  },
  computed: {
    totalAmount() {
      return this.questionList.reduce((prev, cur) => {
        return prev + cur.questionAmount
      }, 0)
    },
    totalPoints() {
      return this.questionList.reduce((prev, cur) => {
        return prev + cur.totalPoints
      }, 0)
    }
  },
  mounted() {
    this.calculateSize(document.body.clientWidth)
    if (this.paperId) {
      this.searchPaperQuestion()
    }
    this.getPaperAbility()
    this.getBank()
  },
  methods: {
    calculateSize(width) {
      if (width <= 1440) {
        this.showSize = 5
      } else if (width > 1440 && width < 1920) {
        this.showSize = 6
      } else {
        this.showSize = 7
      }
    },
    searchPaperQuestion() {
      //查询试卷题目
      this.questionListLoading = true
      paper
        .publicPaperInfo(this.paperId)
        .then(res => {
          if (res.code === 0) {
            this.questionList = res.res.sectionVOS.map(item => {
              item.questionVOS.forEach(question => {
                question.isChecked = false
              })
              let type = this.ytConstant.questionType.getMenu().find(menu => menu.label === item.name)
              return { ...item, questionType: type.value }
            })
          }
        })
        .finally(() => {
          this.questionListLoading = false
        })
    },
    getPaperAbility() {
      //根据examId查看试卷(根据技能分组，新表)
      newExerciseExamApi.getPaperAbility(this.examId).then(res => {
        if (res.code === 0) {
          this.statisticalList = res.res.map(data => {
            return {
              name: data.abilityName,
              questionVOS: data.questions.map(question => {
                return { ...question, questionId: question.id }
              })
            }
          })
        }
      })
    },
    getBank() {
      //获取试卷库列表
      paper.getBank().then(res => {
        this.libraryList = res.res
        this.ruleForm.paperBankId = res.res.find(res => res.name === '默认试卷库').paperBankId
      })
    },
    openByManual() {
      //手动出卷打开
      this.questionListLoading = false
      this.isPackUp = true
    },
    savePaper() {
      //保存试卷
      this.questionListLoading = true
      let payload = []
      this.questionList.forEach(item => {
        item.questionVOS.forEach(question => {
          payload.push({
            points: question.points === 0 || question.points === undefined ? 1 : question.points,
            questionId: question.questionId
          })
        })
      })
      this.$emit('savePaper', payload, () => {
        this.questionListLoading = false
        if (this.mode === 'delete') {
          this.deleteLoading = false
          this.searchPaperQuestion()
        }
      })
    },
    setBigTopicPoint(item, index) {
      //设置题型总分数
      // undefined的时候设置最小值
      let totalPoint = item === undefined ? 0.5 : item
      let total = 0
      let point =
        Math.floor(totalPoint / this.questionList[index].questionAmount) === 0
          ? 0.5
          : Math.floor(totalPoint / this.questionList[index].questionAmount)
      for (let q of this.questionList[index].questionVOS) {
        q.points = point
        total = total + point
      }
      this.questionList[index].totalPoints = total
      this.$forceUpdate()
      this.mode = 'update'
      this.savePaper()
    },
    setSmallTopicPoint(questionId, points, index) {
      //设置每题分数
      this.questionList[index].totalPoints = this.questionList[index].questionVOS.reduce((prev, cur) => {
        if (cur.questionId === questionId && points === undefined) {
          cur.points = 0.5
        }
        return prev + cur.points
      }, 0)
      this.$forceUpdate()
      this.mode = 'update'
      this.savePaper()
    },
    toDelete(data, index) {
      //移除
      if (this.deleteLoading) {
        return
      }
      this.deleteLoading = true
      this.mode = 'delete'
      this.questionList[index].questionVOS.forEach((item, iIndex) => {
        if (item.id === data.id) {
          this.questionList[index].questionVOS.splice(iIndex, 1)
        }
        this.questionList[index].questionAmount = this.questionList[index].questionVOS.length
      })
      this.setBigTopicPoint(this.questionList[index].totalPoints, index)
    },
    handleCheckAllChange(val) {
      //全选
      if (val) {
        this.questionList.forEach(item => {
          item.questionVOS.forEach(question => {
            question.isChecked = true
            this.questionSelection.push(question.questionId)
          })
        })
      } else {
        this.questionList.forEach(item => {
          item.questionVOS.forEach(question => {
            question.isChecked = false
          })
        })
        this.questionSelection = []
      }
      this.isIndeterminate = false
      this.$forceUpdate()
    },
    handleCheckQuestion(value, questionId) {
      //单选
      if (Array.isArray(value) && value.includes(undefined)) {
        return
      }
      if (value) {
        this.questionSelection.push(questionId)
      } else {
        this.questionSelection.splice(this.questionSelection.indexOf(questionId), 1)
      }
      let checkedCount = this.questionSelection.length
      this.isSelectAll = checkedCount === this.totalAmount
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.totalAmount
    },
    addQuestionsModal() {
      // 添加题目弹窗
      this.visible = true
      this.$nextTick(() => {
        //调用选择题目内部方法
        if (this.questionList.length) {
          this.$refs.manual.questionConformity(this.questionList)
        }
      })
    },
    addSuccess() {
      // 选择完题目之后
      this.addLoading = true
      if (this.paperId) {
        let selections = this.$refs['manual'].editorPageExamQuestions()
        let payload = selections.map(question => {
          return { questionId: question.questionId, points: question.points || 1 }
        })
        this.$emit('savePaper', payload, () => {
          this.visible = false
          this.addLoading = false
          this.searchPaperQuestion()
        })
      } else {
        // 没有试卷 创建试卷
        let list = this.$refs['manual'].questionSimpleVOS
        if (list.length === 0) {
          this.$message.warning('试卷题目数量不能为0，请选择')
          return
        }
        this.$emit('createNewPaper', { data: list }, () => {
          this.visible = false
          this.addLoading = false
        })
      }
    },
    storageQ() {
      // 存为试卷打开
      this.ruleForm.name = this.name
      this.storageVisible = true
    },
    determine() {
      // 存为试卷 确定
      if (this.questionList.length === 0) {
        this.$message.warning('题目不能为空')
        return
      }
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          let payload = {
            paperBankId: this.ruleForm.paperBankId,
            name: this.ruleForm.name,
            sectionForms: []
          }
          payload.sectionForms = this.questionList.map(res => {
            return {
              name: res.name,
              questionForms: res.questionVOS.map(item => {
                return {
                  level: 1,
                  questionId: item.questionId,
                  points: item.points
                }
              })
            }
          })
          this.storageLoading = true
          paper
            .saveTemplate(payload)
            .then(res => {
              if (res.code === 0) {
                this.$message.success('存储试卷成功')
                this.storageVisible = false
              }
            })
            .finally(() => {
              this.storageLoading = false
            })
        }
      })
    },
    selectorById(row) {
      // 题目id定位当前位置
      this.selectRowId = row.questionId
      document.querySelector(`#anchor-${row.questionId}`).scrollIntoView({ behavior: 'smooth' })
    },
    toBatchDelete() {
      //批量删除弹窗
      if (this.questionSelection.length === 0) {
        return this.$message.warning('请选择删除的题目')
      }
      this.deleteContent = '选择的'
      this.$refs['batchDeleteDialog'].open()
    },
    delQuestion(row) {
      //删除该题型或者技能下的所有题目
      this.questionSelection = row.questionVOS.map(item => {
        return item.questionId
      })
      this.deleteContent = `该${this.statistical ? '题型' : '技能'}下的`
      // this.delRows = row
      this.$refs['batchDeleteDialog'].open()
    },
    batchDelete() {
      //删除题型中的
      this.questionList = this.questionList.map(list => {
        list.questionVOS = list.questionVOS.filter(question => {
          return !this.questionSelection.includes(question.questionId)
        })
        list.questionAmount = list.questionVOS.length
        return list
      })
      //删除技能中的
      this.statisticalList = this.statisticalList.map(list => {
        list.questionVOS = list.questionVOS.filter(question => {
          return !this.questionSelection.includes(question.questionId)
        })
        return list
      })
      this.mode = 'delete'
      this.savePaper()
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/variables';
@import '~@/theme/filterDropdownMenu';
.yt-container {
  padding: 0;
  width: 100%;
  height: 100%;
}
.right-container {
  height: 100%;
  flex-shrink: 0;
  margin-left: 10px;
  padding-top: 10px;
  background: #fff;
  position: relative;
  .pack-up-button {
    font-size: 18px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    transition: all 0.2s ease-in-out;
  }
  .is-pack-up {
    display: none;
  }
  .menu {
    .flexStyle();
    width: 240px;
    margin: 0 auto;
    height: 20px;
    background: #f0f0f0;
    border-radius: 36px;
    p {
      width: 120px;
      height: 20px;
      line-height: 20px;
      border-radius: 36px;
      color: #666666;
      text-align: center;
      cursor: pointer;
      &.active {
        color: #ffffff;
        background: #448bff;
      }
    }
  }
  .header {
    .flexStyle(flex, space-between);
    .font(14px, bold, #282c3d);
    height: 50px;
    padding: 0 10px;
    border-bottom: 2px solid #e2e4e8;
  }
  .question-type {
    height: calc(100% - 70px);
    padding: 10px 20px 0 20px;
    overflow-y: auto;
    li {
      padding-bottom: 10px;
      border-bottom: 1px solid #f0f0f0;
      &:not(:first-child) {
        margin-top: 10px;
      }
      &:last-child {
        border-bottom: none;
      }
    }
    .type {
      width: 3px;
      height: 10px;
      background: #448bff;
      opacity: 1;
      border-radius: 2px;
      /*margin-top: 8px;*/
      margin-right: 5px;
    }
    .question-number {
      flex-wrap: wrap;
      .number {
        border-radius: 3px;
        .wh(24px, 24px);
        border: 1px solid #d9d9d9;
        margin: 10px 12px 0 0;
        &:hover {
          cursor: pointer;
        }
      }
      .selectRow {
        background: #448bff;
        border: 0;
        color: #fff;
      }
    }
  }
}
.yt-content {
  background-color: #f3f4f7;
  padding: 0;
  box-shadow: none;
}
//全选
.select-all {
  .flexStyle(flex, space-between, center);
  padding-left: 20px;
  height: 55px;
  background: #ffffff;
  border-bottom: 1px solid rgba(226, 228, 232, 0.3);
  .el-checkbox {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    ::v-deep .el-checkbox__label {
      font-size: @small;
      color: rgba(0, 0, 0, 0.85) !important;
    }
  }
}
//题目列表
.question-container {
  height: calc(100% - 55px);
  overflow-y: auto;
  .list {
    background-color: #f3f4f7;
  }
  .item {
    display: flex;
    background-color: #ffffff;
    margin-bottom: 9px;
    &:last-child {
      margin-bottom: 0;
    }
    .checkbox {
      height: 16px;
      margin-top: 19px;
      margin-right: 4px;
      margin-left: 20px;
      ::v-deep .el-checkbox__label {
        display: none;
      }
    }
    .header {
      .flexStyle(flex, flex-start);
      padding-left: 20px;
      margin: 16px 0 6px;
      .question-type {
        width: 23px;
        height: 23px;
        line-height: 22px;
        text-align: center;
        color: #ffffff;
        font-weight: bold;
        background: #448bff;
        border-radius: 50%;
        margin-left: 4px;
        margin-right: 16px;
        font-size: 14px;
      }
      .audit {
        .flexStyle(flex, center, center);
        height: 23px;
        overflow: hidden;
      }
      svg {
        font-size: 68px;
      }
    }
    .footer {
      .flexStyle(flex, space-between, center);
      height: 20px;
      margin: 10px 0;
      font-size: @small;
      padding: 0 20px;
      div {
        .flexStyle(flex, flex-start, center);
      }
      p {
        height: 18px;
        line-height: 20px;
        border-right: 1px solid #d8d8d8;
        padding-right: 18px;
        margin-right: 16px;
        &:last-child {
          height: 18px;
          border-right: 0px solid #d8d8d8;
          line-height: 20px;
          padding-right: 18px;
          margin-right: 16px;
          cursor: pointer;
        }
      }
    }
  }
}
.empty {
  .flexStyle(flex, center, center);
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
  svg {
    font-size: 100px;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #000000;
    span {
      color: #438bff;
      border-bottom: 1px solid #438bff;
      cursor: pointer;
    }
  }
}
.question-sort {
  width: 100%;
  height: 50px;
  background: #f0f0f0;
  opacity: 1;
  border-radius: 6px 6px 0 0;
  display: flex;
  padding: 15px 0 15px 20px;
  p {
    font-size: 14px;
    line-height: 22px;
    color: #282c3d;
    opacity: 1;
    margin-right: 5px;
  }
  .el-input {
    width: 70px;
    ::v-deep .el-input__inner {
      height: 24px;
      border: 1px solid #d9d9d9;
      border-radius: 3px;
      text-align: center;
    }
  }
}
.btn-del:hover,
.del-svg:hover {
  svg {
    fill: #448bff !important;
  }
}
.el-input-number {
  max-width: 60px;
  ::v-deep .el-input {
    height: 20px;
    line-height: 22px;
  }
  ::v-deep .el-input__inner {
    height: 20px;
  }
}
.question-choose-dialog {
  ::v-deep .el-dialog {
    .el-dialog__body {
      height: 80vh;
      padding: 0;
    }
    .el-dialog__footer {
      .footer {
        .flexStyle(flex);
        margin: 0 60px;
      }
    }
  }
}

::v-deep .el-loading-mask {
  z-index: auto;
}
</style>
