<template>
  <div class="yt-container">
    <!--  基本信息  -->
    <el-row>
      <el-col :span="6" style="padding-right: 10px">
        <div class="yt-content">
          <h3>基本信息</h3>
          <ul class="basic-info">
            <li v-for="(basic, key, index) in basicInfo" :key="index">
              <div class="dot" :style="{ background: basic.color }" />
              <p>{{ basic.name }}</p>
              <div style="margin-right: 10px">{{ basic.value }}</div>
            </li>
          </ul>
        </div>
      </el-col>
      <el-col :span="8" style="padding-right: 10px">
        <div class="yt-content" style="padding: 10px 10px 0 10px">
          <h3>技能下题目数量</h3>
          <div ref="questionPieChart" style="height: 188px"></div>
        </div>
      </el-col>
      <el-col :span="10">
        <div class="yt-content" style="position: relative">
          <h3 style="position:absolute">技能得分情况</h3>
          <div ref="questionBarChart" style="height: 188px;margin-top:14px"></div>
        </div>
      </el-col>
    </el-row>
    <div class="yt-content">
      <h3>分数分布</h3>
      <el-row :gutter="17">
        <el-col :span="13">
          <div ref="distributionChart" :style="{ height: distributionHeight }"></div>
        </el-col>
        <el-col :span="11">
          <el-table :data="distributionList" ref="distributionTable" class="yt-table">
            <el-table-column label="分数段" prop="space" align="center" />
            <el-table-column label="人数" prop="num" align="center" />
            <el-table-column label="占总人数百分比" align="center">
              <template slot-scope="scope">{{ $roundFloat(scope.row.frequency, 2) }}%</template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </div>
    <div
      class="yt-content"
      style="padding:10px 10px 20px 10px"
      :style="{
        height: isSkillPackUp ? '100%' : '50px',
        overflow: 'hidden'
      }"
    >
      <div class="title">
        <h3>总体技能掌握情况</h3>
        <div class="pack-up" @click="isSkillPackUp = !isSkillPackUp">
          <p>{{ !isSkillPackUp ? '展开' : '收起' }}</p>
          <YTIcon :class="['icon', isSkillPackUp ? 'active' : '']" :href="'#icon-open'"></YTIcon>
        </div>
      </div>
      <h4>总体技能分析</h4>
      <el-row>
        <el-col :span="7" class="has-border">
          <div ref="skillOverallRadarChart" :style="{ height: skillOverallHeight }"></div>
        </el-col>
        <el-col :span="8">
          <el-table :data="skillOverallList" ref="skillOverallTable" class="yt-table col-table">
            <el-table-column label="技能名称" prop="abilityName" align="center" :width="120" show-overflow-tooltip />
            <el-table-column label="描述" prop="description" align="center" show-overflow-tooltip />
            <el-table-column label="平均掌握率" width="120" align="center">
              <template slot-scope="scope">{{ $roundFloat(scope.row.averageRate, 2) }}%</template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="9" class="has-border right">
          <div ref="skillOverallBarChart" :style="{ height: skillOverallHeight }"></div>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <h4 style="margin-bottom: 20px">详细技能分析</h4>
      <div v-for="(item, index) in skillList" :key="index">
        <h5>{{ item.abilityName }}详细技能掌握情况</h5>
        <el-row>
          <el-col :span="7" class="has-border">
            <div :ref="'subSkillRadarChart-' + item.id" :style="{ height: item.tableHeight }"></div>
          </el-col>
          <el-col :span="9">
            <el-table :data="item.abilityScoreRateVOS" ref="subAbilityRateTable" class="yt-table col-table">
              <el-table-column label="技能名称" prop="abilityName" align="center" :width="120" show-overflow-tooltip />
              <el-table-column label="描述" prop="description" align="center" show-overflow-tooltip />
              <el-table-column label="掌握率" width="80" align="center">
                <template slot-scope="scope">{{ $roundFloat(scope.row.averageRate, 2) }}%</template>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :span="8" class="has-border right">
            <div :ref="'subSkillBarChart-' + item.id" :style="{ height: item.tableHeight }"></div>
          </el-col>
        </el-row>
        <h4 class="description" v-if="item.description !== null && item.description !== ''">技能描述:{{ item.description }}</h4>
        <el-divider v-if="index !== skillList.length - 1"></el-divider>
      </div>
    </div>
    <!--  考试答题情况  -->
    <div
      class="yt-content answer-table"
      v-if="answerData.length > 0"
      :style="{
        height: isAnswerPackUp ? '100%' : '50px',
        overflow: 'hidden'
      }"
    >
      <div class="title">
        <h3>本场考试答题情况</h3>
        <div class="pack-up" @click="isAnswerPackUp = !isAnswerPackUp">
          <p>{{ !isAnswerPackUp ? '展开' : '收起' }}</p>
          <YTIcon :class="['icon', isAnswerPackUp ? 'active' : '']" :href="'#icon-open'"></YTIcon>
        </div>
      </div>
      <el-table :data="answerTableData" class="yt-table list" width="100%" style="margin-top:12px">
        <el-table-column label="题目序号" min-width="80">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column label="题型" min-width="100" prop="questionType" />
        <el-table-column label="难度" min-width="100" prop="difficulty" />
        <el-table-column label="技能" min-width="180" prop="abilityNames" show-overflow-tooltip>
          <template slot-scope="scope">
            <span style="word-break: keep-all">{{ scope.row.abilityNames }}</span>
          </template>
        </el-table-column>
        <!--          <el-table-column label="时长" min-width="135" prop="time" />-->
        <!--          <el-table-column label="历史平均率" min-width="150">-->
        <!--            <template slot-scope="scope">{{ scope.row.historyRate }}%</template>-->
        <!--          </el-table-column>-->
        <el-table-column label="本次测评平均掌握率" min-width="80">
          <template slot-scope="scope">{{ scope.row.testRate === 1 ? '100' : Math.round((scope.row.testRate || 0) * 100) }}%</template>
        </el-table-column>
      </el-table>
      <div style="padding:0 35px">
        <Page
          class="yt-page"
          :class="'yt-page-' + (answerTotal.toString().length > 1 ? answerTotal.toString().length : 2)"
          :total="answerTotal"
          :current="answerPageNum + 1"
          :page-size-opts="[10, 20, 40, 100]"
          :page-size="answerPageSize"
          show-elevator
          show-sizer
          show-total
          @on-change="changePageAnswer"
          @on-page-size-change="changePageSizeAnswer"
        />
      </div>
    </div>

    <!--  考试评价  -->
    <div class="yt-content">
      <div class="yt-flex-layout title-desc" style="justify-content: space-between">
        <h3>本次考试评价 <YTIcon @click="isEvaluate = !isEvaluate" :href="'#icon-zuoti'" style="fill: #FFBB00" /></h3>
      </div>
      <el-input
        placeholder="请为本场考试做个评价:"
        v-show="isEvaluate"
        type="textarea"
        size="small"
        :autosize="{ minRows: 4 }"
        style="padding: 9px 20px"
        :maxlength="300"
        @blur="submitComment"
        v-model.trim="evaluate"
      />
      <div v-show="!isEvaluate" v-html="evaluate" style="text-align: left;padding: 0 30px" />
    </div>
    <div
      class="yt-content"
      :style="{
        height: isRankPackUp ? '100%' : '50px',
        overflow: 'hidden'
      }"
    >
      <div class="title">
        <h3>考生排名</h3>
        <div class="pack-up" @click="isRankPackUp = !isRankPackUp">
          <p>{{ !isRankPackUp ? '展开' : '收起' }}</p>
          <YTIcon :class="['icon', isRankPackUp ? 'active' : '']" :href="'#icon-open'"></YTIcon>
        </div>
      </div>
      <el-row class="rank" :gutter="20">
        <el-col :span="12">
          <el-table :data="rankTableData.slice(0, pageSize / 2)" class="yt-table list">
            <el-table-column label="名次" prop="rank" align="center" />
            <el-table-column label="姓名" prop="userName" align="center" />
            <el-table-column label="总分" prop="score" align="center" />
          </el-table>
        </el-col>
        <el-col :span="12">
          <el-table :data="rankTableData.slice(pageSize / 2, pageSize)" class="yt-table list">
            <el-table-column label="名次" prop="rank" align="center" />
            <el-table-column label="姓名" prop="userName" align="center" />
            <el-table-column label="总分" prop="score" align="center" />
          </el-table>
        </el-col>
      </el-row>
      <div style="padding:0 35px">
        <Page
          class="yt-page"
          :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
          :total="total"
          :current="pageNum + 1"
          :page-size-opts="[10, 20, 40, 100]"
          :page-size="pageSize"
          show-elevator
          show-sizer
          show-total
          @on-change="changePage"
          @on-page-size-change="changePageSize"
        />
      </div>
    </div>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import examApi from '@api/exam'
import { pdfD2 } from '@util/pdfDown2'
import examResultApi from '@api/examResult'
import examProcessApi from '@api/examProcess'
import pieLoopChart from '@components/manage/exam/list/reportCharts/pieLoopChart'
import barLineChart from '@components/manage/exam/list/reportCharts/barLineChart'
import radarChart from '@components/manage/exam/list/reportCharts/radarChart'
import barHorizontalChart from '@components/manage/exam/list/reportCharts/barHorizontalChart'
import barStackChart from '@components/manage/exam/list/reportCharts/barStackChart'
import barDoubleChart from '@components/manage/exam/list/reportCharts/barDoubleChart'
export default {
  name: 'overallExamination',
  components: { YTIcon },
  props: ['examType'],
  data() {
    return {
      total: 0,
      pageNum: 0,
      pageSize: 10,
      answerTotal: 0,
      answerPageNum: 0,
      answerPageSize: 10,
      formData: {
        departmentIds: [],
        examId: '',
        tagIds: [],
        userIds: []
      },
      basicInfo: {
        candidateNum: {
          color: '#448BFF',
          name: '考试总人数',
          value: null
        },
        highestScore: {
          color: '#FF5050',
          name: '最高分',
          value: null
        },
        lowestScore: {
          color: '#FFBB00',
          name: '最低分',
          value: null
        },
        averageScore: {
          color: '#4CE47A',
          name: '平均分',
          value: null
        },
        medianScore: {
          color: '#FFDB7A',
          name: '中位数',
          value: null
        }
      },
      questionPieChart: null, //技能下题目数
      questionBarChart: null,
      distributionList: [], //分数分布
      distributionHeight: '0px', //分数分布行高
      isSkillPackUp: true, //技能掌握情况收起
      skillOverallList: [], //总体技能掌握
      skillOverallHeight: '0px', //总体技能掌握行高
      skillOverallRadarChart: null,
      skillOverallBarChart: null,
      skillList: [], //详细技能
      isAnswerPackUp: true,
      answerData: [],
      qType: ['判断题', '单选题', '多选题', '填空题', '简答题', '代码题'],
      showAbility: true,
      showAnswer: true,
      isEvaluate: false,
      evaluate: null,
      isRankPackUp: true,
      rankList: [], //排名
      rankTableData: [], //分页后的排名
      answerTableData: [] //分页后的答题情况
    }
  },
  mounted() {
    window.addEventListener(
      'resize',
      () => {
        if (this.myChart1) {
          this.myChart1.resize()
        }
        if (this.myChart2) {
          this.myChart2.resize()
        }
        if (this.myChart3) {
          this.myChart3.resize()
        }
      },
      false
    )
  },
  methods: {
    //data
    init() {
      //页面初始化
      this.formData.examId = this.$parent.examId
      this.$nextTick(() => {
        this.getBaseInfo()
        this.getQuestionNum()
        this.getQuestionScore()
        this.getScoreStatistics()
        this.getMainAbilityRate()
        this.getSubAbilityRate()
        this.answerExamInfo()
        this.examEvaluation()
        this.getExamRank()
      })
    },
    getBaseInfo() {
      //获取考试基本信息
      examResultApi.getAllSimple(this.formData).then(res => {
        Object.keys(this.basicInfo).forEach(item => {
          this.basicInfo[item].value = res.res[item]
        })
      })
    },
    getQuestionNum() {
      //题目数量
      examProcessApi.getQuestionNum(this.formData).then(res => {
        //答题情况饼图
        let pieOption = this.$deepCopy(pieLoopChart.option)
        pieOption.series[0].center = ['50%', '50%']
        pieOption.series[0].radius = [52, 70]
        pieOption.series[0].label.fontSize = '12px'
        pieOption.series[0].label.width = 90
        pieOption.series[0].labelLine.length2 = 30
        pieOption.series[0].label.overflow = 'truncate'
        pieOption.series[0].data = res.res.map(item => {
          return { value: item.questionCount, name: item.abilityName }
        })
        this.questionPieChart = this.$echarts.init(this.$refs.questionPieChart)
        this.questionPieChart.setOption(pieOption)
      })
    },
    getQuestionScore() {
      //题目得分
      examProcessApi.getScore(this.formData).then(res => {
        //答题情况柱状图
        let barOption = this.$deepCopy(barStackChart.option)
        barOption.tooltip = {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        }
        barOption.legend = {
          data: ['得分', '失分'],
          top: 0,
          right: 0,
          itemWidth: 16,
          itemHeight: 8,
          itemGap: 20,
          textStyle: {
            fontSize: '14px'
          }
        }
        barOption.grid = { top: 20, bottom: 21, left: 27, right: 7 }
        barOption.dataZoom = [{ type: 'inside' }]
        barOption.xAxis.axisLabel.fontSize = 12
        barOption.xAxis.axisLabel.width = 40
        barOption.xAxis.axisLabel.overflow = 'truncate'
        barOption.xAxis.data = res.res.map(item => {
          return item.abilityName
        })
        barOption.yAxis.axisLabel.fontSize = 12
        barOption.series[0].label.fontSize = 12
        barOption.series[0].data = res.res.map(item => {
          return item.points === 0 ? null : item.points
        })
        delete barOption.series[0].barWidth
        barOption.series[1].label.fontSize = 12
        barOption.series[1].data = res.res.map(item => {
          return item.lostPoints === 0 ? null : item.lostPoints
        })
        delete barOption.series[1].barWidth
        this.questionBarChart = this.$echarts.init(this.$refs.questionBarChart)
        this.questionBarChart.setOption(barOption)
      })
    },
    getScoreStatistics() {
      //分数分布
      examProcessApi.getScoreStatistics(this.formData, 10).then(res => {
        this.distributionList = res.res
        this.$nextTick(() => {
          this.distributionHeight = `${this.computeRowHeight(this.$refs.distributionTable.$el.clientHeight)}px`
          let barOption = this.$deepCopy(barLineChart.option)
          barOption.grid = {
            top: 40,
            bottom: 20,
            left: 30,
            right: 60
          }
          barOption.legend.data = ['人数', '比率']
          barOption.legend.textStyle.fontSize = '12px'
          barOption.legend.itemWidth = 16
          barOption.legend.itemHeight = 8
          barOption.legend.right = 60
          barOption.xAxis.axisLabel.fontSize = '12px'
          barOption.yAxis[0].axisLabel.padding = [0, 0, 0, 0]
          barOption.yAxis[0].axisLabel.fontSize = '12px'
          barOption.yAxis[1].axisLabel.fontSize = '12px'
          barOption.xAxis.data = res.res.map(item => {
            return item.space
          })
          barOption.yAxis[1].axisLabel.formatter = (value, index) => {
            return this.$roundFloat(value, 0) + '%'
          }
          barOption.series[0].data = res.res.map(item => {
            return item.num
          })
          barOption.series[1].data = res.res.map(item => {
            return item.frequency
          })
          this.$nextTick(() => {
            this.distributionChart = this.$echarts.init(this.$refs.distributionChart)
            this.distributionChart.setOption(barOption)
          })
        })
      })
    },
    getMainAbilityRate() {
      //获取主技能分析
      examProcessApi.getMainAbilityRate(this.formData).then(res => {
        this.skillOverallList = res.res
        this.$nextTick(() => {
          this.skillOverallHeight = `${this.computeRowHeight(this.$refs.skillOverallTable.$el.clientHeight)}px`
          //技能整体掌握雷达图
          let radarOption = this.$deepCopy(radarChart.option)
          radarOption.legend.textStyle.fontSize = 12
          radarOption.radar[0].radius = this.skillOverallList.length > 9 ? 79 : 45
          radarOption.radar[0].axisName.fontSize = 12
          radarOption.radar[0].center = ['50%', '60%']
          radarOption.legend.itemWidth = 6
          radarOption.legend.itemHeight = 6
          radarOption.legend.data = [{ icon: 'circle', name: '平均掌握率' }]
          radarOption.radar[0].axisName.formatter = value => {
            return value.length > 10 ? value.slice(0, 9) + '...' : value
          }
          radarOption.radar[0].indicator = res.res.map(item => {
            return { name: item.abilityName, max: 1 }
          })
          radarOption.series[0].symbolSize = 5
          radarOption.series = [
            {
              type: 'radar',
              symbol: 'circle',
              symbolSize: 5,
              data: [
                {
                  value: res.res.map(item => {
                    return item.averageRate
                  }),
                  name: '平均掌握率',
                  itemStyle: {
                    color: '#F16E57', //改变折线点的颜色
                    lineStyle: {
                      color: '#F16E57' //改变折线颜色
                    }
                  }
                }
              ]
            }
          ]
          //技能整体掌握柱状图
          let barOption = this.$deepCopy(barHorizontalChart.option)
          barOption.grid = {
            top: 30,
            bottom: 21,
            left: 70,
            right: 70
          }
          barOption.yAxis[0].axisLabel.fontSize = '12px'
          barOption.yAxis[0].nameTextStyle.fontSize = '12px'
          barOption.yAxis[0].axisLabel.interval = 0
          barOption.yAxis[0].axisLabel.width = 55
          barOption.yAxis[0].axisLabel.overflow = 'truncate'
          barOption.xAxis.axisLabel.fontSize = '12px'
          barOption.yAxis[0].data = res.res.map(item => {
            return item.abilityName
          })
          barOption.yAxis[1].axisLabel.align = 'right'
          barOption.yAxis[1].axisLabel.margin = -3
          barOption.yAxis[1].nameTextStyle.align = 'right'
          barOption.yAxis[1].axisLabel.fontSize = '12px'
          barOption.yAxis[1].nameTextStyle.fontSize = '12px'
          barOption.series[0].barWidth = 20
          barOption.yAxis[1].data = res.res.map(item => {
            return this.$roundFloat(item.averageRate, 2) + '%'
          })
          barOption.series[0].data = res.res.map(item => {
            return this.$roundFloat(item.averageRate, 2)
          })
          this.$nextTick(() => {
            this.skillOverallRadarChart = this.$echarts.init(this.$refs.skillOverallRadarChart)
            this.skillOverallRadarChart.setOption(radarOption)
            this.skillOverallBarChart = this.$echarts.init(this.$refs.skillOverallBarChart)
            this.skillOverallBarChart.setOption(barOption)
          })
        })
      })
    },
    getSubAbilityRate() {
      examProcessApi.getSubAbilityRate(this.formData).then(res => {
        this.skillList = res.res
          .filter(skill => {
            return skill.abilityScoreRateVOS.length > 0
          })
          .map(skill => {
            return {
              id: this.$generateUUID(),
              subSkillRadarChart: null,
              subSkillBarChart: null,
              tableHeight: '0px',
              ...skill
            }
          })
        this.$nextTick(() => {
          this.skillList.forEach((item, index) => {
            item.tableHeight = `${this.computeRowHeight(this.$refs.subAbilityRateTable[index].$el.clientHeight)}px`
          })
          this.$nextTick(() => {
            this.skillList = this.skillList.map(skill => {
              //技能详细掌握雷达图
              try {
                let radarOption = this.$deepCopy(radarChart.option)
                if (skill.abilityScoreRateVOS.length < 3) {
                  radarOption.radar[0].startAngle = 180
                }
                radarOption.legend.textStyle.fontSize = '12px'
                radarOption.legend.itemWidth = 6
                radarOption.legend.itemHeight = 6
                radarOption.radar[0].indicator = skill.abilityScoreRateVOS.map(item => {
                  return { name: item.abilityName, max: 1 }
                })
                radarOption.radar[0].axisName.fontSize = '12px'
                radarOption.radar[0].radius = skill.abilityScoreRateVOS.length > 9 ? 79 : 45
                radarOption.radar[0].center = ['50%', '60%']
                radarOption.radar[0].axisName.formatter = value => {
                  return value.length > 10 ? value.slice(0, 9) + '...' : value
                }
                radarOption.series[0].symbolSize = 5
                radarOption.series[0].data[0].value = skill.abilityScoreRateVOS.map(item => {
                  return item.averageRate
                })
                radarOption.series[0].data[0].itemStyle.color = '#7CAEEF'
                radarOption.series[0].data[0].itemStyle.lineStyle.color = '#7CAEEF'
                skill.subSkillRadarChart = this.$echarts.init(this.$refs[`subSkillRadarChart-${skill.id}`][0])
                skill.subSkillRadarChart.setOption(radarOption)
                //技能详细掌握雷达图
                let barOption = this.$deepCopy(barDoubleChart.option)
                barOption.grid = {
                  top: 33,
                  left: 70,
                  right: 70,
                  bottom: 5
                }
                barOption.legend.left = 'center'
                barOption.legend.itemWidth = 16
                barOption.legend.itemHeight = 8
                barOption.legend.itemGap = 12
                barOption.legend.textStyle.fontSize = 12
                barOption.legend.textStyle.padding = [0, 0, 0, 0]
                barOption.yAxis.data = skill.abilityScoreRateVOS.map(item => {
                  return item.abilityName
                })
                barOption.yAxis.axisLabel.fontSize = '12px'
                barOption.yAxis.nameTextStyle.fontSize = '12px'
                barOption.yAxis.nameTextStyle.padding = [0, 5, 0, 0]
                barOption.yAxis.axisLabel.padding = [0, 0, 0, 0]
                barOption.yAxis.axisLabel.width = 50
                barOption.yAxis.axisLabel.overflow = 'truncate'
                barOption.yAxis.axisLabel.interval = 0
                barOption.series[0].barWidth = 10
                barOption.series[0].label.formatter = ({ data }) => {
                  return parseInt(data) + '%'
                }
                barOption.series[0].data = skill.abilityScoreRateVOS.map(item => {
                  return this.$roundFloat(item.averageRate, 2)
                })
                barOption.series.push({
                  name: '平均掌握率',
                  type: 'bar',
                  data: [],
                  label: {
                    show: true,
                    formatter: function({ data }) {
                      return parseInt(data) + '%'
                    },
                    position: 'right'
                  },
                  color: '#E8EAEE',
                  barWidth: 10,
                  barGap: 0
                })
                skill.subSkillBarChart = this.$echarts.init(this.$refs[`subSkillBarChart-${skill.id}`][0])
                skill.subSkillBarChart.setOption(barOption)
              } catch (e) {}
              return skill
            })
          })
        })
      })
    },
    answerExamInfo() {
      // 本场考试大题情况列表信息
      // 模拟id2020
      let examId = this.formData.examId
      let difficult = ['', '', '', '简单', '', '中等', '', '困难']
      this.answerData = []
      examApi.getNewQuestions(examId).then(res => {
        this.answerData = res.res.map(r => {
          return {
            questionType: this.qType[r['quesType']],
            testRate: r['rate'],
            difficulty: difficult[r['difficulty']],
            historyRate: '5.55',
            abilityNames: r['abilityNames'] ? r['abilityNames'].toString() : '暂无'
          }
        })
        this.answerTotal = this.answerData.length
        this.answerTableData = this.answerData.filter((item, index) => index < 10)
      })
    },
    searchAnswerData() {
      this.answerTableData = this.answerData.filter(
        (item, index) => index < (this.answerPageNum + 1) * this.answerPageSize && index >= this.answerPageSize * this.answerPageNum
      )
    },
    changePageAnswer(answerPageNum) {
      this.answerPageNum = answerPageNum - 1
      this.searchAnswerData()
    },
    changePageSizeAnswer(answerPageSize) {
      this.answerPageNum = 0
      this.answerPageSize = answerPageSize
      this.searchAnswerData()
    },
    getExamRank() {
      //获取排名
      examProcessApi.getExamRank(this.formData).then(res => {
        this.rankList = res.res
        this.total = Object.keys(this.rankList).length
        this.rankTableData = this.rankList.filter((item, index) => index < 10)
      })
    },
    searchExamRank() {
      //排名手动分页处理
      this.rankTableData = this.rankList.filter((item, index) => index < (this.pageNum + 1) * this.pageSize && index >= this.pageSize * this.pageNum)
    },
    changePage(pageNum) {
      this.pageNum = pageNum - 1
      this.searchExamRank()
    },
    changePageSize(pageSize) {
      this.pageNum = 0
      this.pageSize = pageSize
      this.searchExamRank()
    },
    //events
    //utils
    computeRowHeight(height) {
      //计算chart高度
      return height < 80 ? 80 : height
    },
    loadingEle() {
      // 加载echarts
      this.typeLoading = true
      this.pointsLoading = true
      this.abilityLoading = true
    },
    setExamId(examId) {
      this.loadingEle()
      this.examId = examId
      this.getInfo()
    },
    exportPdf() {
      pdfD2(document.getElementById('examReport'), '考试结果分析报告.pdf')
        .then(() => {
          this.$overSituationMsg('导出成功!', 'success', this)
        })
        .catch(() => {
          this.$overSituationMsg('导出失败!', 'error', this)
        })
        .finally(() => {
          this.$parent.loading = false
        })
    },
    submitComment() {
      let form = {
        comment: this.evaluate,
        examId: this.formData.examId,
        order: 4
      }
      examApi.saveEvaByExamReport(form).then(res => {
        if (res.code === 0 && res.res) {
          this.$overSituationMsg('本场考试评价录入成功', 'success', this)
          this.isEvaluate = false
          this.evaluate = res.res
        }
      })
    },
    examEvaluation() {
      // 获取本场考试评价
      // 请使用接口数据order=4
      examApi.getEvaByExamReport(this.formData.examId).then(res => {
        res.res.forEach(r => {
          if (r.order === 4) {
            this.evaluate = r.comment
          }
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.yt-container {
  margin-top: 20px;
  padding: 10px;
  background-color: #fff;
  height: auto;
  min-height: calc(100% - 175px);
}
.yt-content {
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  h3 {
    font-size: @default;
  }
  h4 {
    margin: 20px 0 25px 10px;
    font-size: @medium;
  }
  h5 {
    padding-left: 10px;
    margin-left: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: @medium;
    color: #448bff;
    position: relative;
    &:before {
      content: '';
      width: 3px;
      height: 12px;
      background: #448bff;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto 0;
      border-radius: 10px;
    }
  }
  &.answer-table {
    ::v-deep .el-table td:first-child {
      display: flex;
      justify-content: center;
      /*padding-left: 30px;*/
    }
    ::v-deep.el-table__header thead th:first-child .cell {
      display: flex;
      justify-content: center;
      /*padding-left: 40px;*/
    }
  }
  .title {
    .flexStyle(flex, space-between);
  }
  .rank {
    .flexStyle(flex, space-between, flex-start);
    margin-top: 12px;
  }
  .yt-table {
    ::v-deep .el-table__header thead th {
      height: 36px;
    }
    ::v-deep .el-table__row {
      height: 36px;
    }
    &.list {
      ::v-deep .el-table__row {
        height: 36px;
      }
    }
  }
  ::v-deep .el-table td,
  .el-table th {
    padding: 6px 0;
  }
}
.has-border {
  position: relative;
  &.right {
    &:after {
      left: 0;
    }
  }
  &:after {
    content: '';
    width: 1px;
    height: calc(100% - 50px);
    background-color: #e2e4e8;
    position: absolute;
    right: 0;
    top: 34px;
    margin: auto 0;
  }
}
.col-table {
  width: calc(100% - 40px);
  margin: 0 20px;
}
.el-divider {
  width: calc(100% + 20px);
  height: 10px;
  margin: 20px 0 0 -10px;
  background-color: #f8f8f8;
}
.basic-info {
  margin-top: 8px;
  li {
    .flexStyle(flex, space-between);
    padding: 10px 0 5px;
    border-bottom: 1px solid #f0f0f0;
    .dot {
      width: 4px;
      height: 4px;
      margin: 0 7px 0 5px;
      border-radius: 50%;
    }
    p {
      flex-grow: 1;
    }
  }
}
.pack-up {
  width: 53px;
  height: 25px;
  line-height: 25px;
  background: #f7f7f7;
  border-radius: 13px;
  border: 1px solid #e6e6e6;
  cursor: pointer;
  .flexStyle(flex, flex-start, center);
  p {
    margin-left: 8px;
    color: #888888;
    font-size: @small;
    width: 24px !important;
    user-select: none; //不被选中
  }
  svg {
    width: 9px;
    height: 14px;
    margin-left: 4px;
    margin-top: 14px;
    margin-right: 6px;
    transition: all 0.2s ease-in-out;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .active {
    -webkit-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg);
  }
}
.description {
  color: #9b9b9b;
  font-size: 14px;
  font-weight: 400;
}
.description:before {
  content: '';
  width: 0px;
  height: 0px;
  background: #448bff;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  border-radius: 10px;
}
</style>
