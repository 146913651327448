import config from '../config/config'
import { axios } from './index'

const baseUrl = config.baseUrl + '/user/api/v2/staff'
const tabUrl = config.baseUrl + '/user/api/v1'

export default {
  //多条件查询员工
  getStaffSearch(payload) {
    return axios.post(`${baseUrl}/search?sort=${payload.sort}&page=${payload.page}&size=${payload.size}`, payload)
  }
  // //获取某种类型的全部标签 不分页 4:人员标签 3:岗位标签
  // getTabId(payload) {
  //   return axios.get(`${tabUrl}/tab/get/all/by/type?type=${payload ? payload : 4}`)
  // }
}
