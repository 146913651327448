<template>
  <div class="yt-container" ref="resultContainer" :style="{ height: containerHeight }">
    <div class="yt-content" style="padding: 10px 20px; margin-bottom: 0">
      <el-table
        class="yt-table personal-table"
        ref="personalTable"
        v-loading="loading"
        :data="personalTestData"
        :style="{ height: tableHeight }"
        @selection-change="selectChange"
        @row-click="handleRowClick"
        highlight-current-row
        @sort-change="setSort"
      >
        <el-table-column width="31" type="selection" align="center" />
        <el-table-column v-if="$parent.userType === 0" label="工号" prop="idNumber" show-overflow-tooltip />
        <el-table-column label="姓名" prop="name" />
        <el-table-column v-if="$parent.userType === 0" label="部门" prop="department" show-overflow-tooltip />
        <el-table-column v-else label="邮箱" prop="email" />
        <el-table-column v-if="$parent.userType === 0" label="岗位" prop="post" show-overflow-tooltip />
        <el-table-column v-else label="手机号" prop="phone" show-overflow-tooltip />
        <el-table-column label="考试开始时间" prop="startTime" sortable="custom" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.startTime !== undefined">{{ $formatTime(scope.row.startTime, 'yyyy-MM-dd hh:mm:ss') }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column label="考试用时" prop="testTime" sortable="custom" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.testTime !== undefined">{{ scope.row.testTime }}分钟</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        <el-table-column label="总分" sortable="custom" prop="totalMark" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.totalMark }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="170">
          <template slot-scope="scope">
            <span
              class="tool-button"
              v-show="scope.row.isShowReport"
              :style="scope.row.testTime === undefined ? 'cursor: no-drop' : ''"
              @click="postModal(scope.row)"
              >训前报告</span
            >
            <span class="tool-button" :style="scope.row.testTime === undefined ? 'cursor: no-drop' : ''" @click="handleSee(scope.row)">考试详情</span>
            <span
              v-show="scope.row.answerPaperStatus === 6"
              class="tool-button"
              :style="scope.row.testTime === undefined ? 'cursor: no-drop' : ''"
              @click.stop="myReport(scope.row)"
              >个人报告</span
            >
            <el-dropdown v-show="scope.row.answerPaperStatus === 6" placement="bottom-start" @command="handleCommand($event, scope.row)">
              <span @click.self="handleClick" class="tool-button">更多</span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="update">上传报告</el-dropdown-item>
                <el-dropdown-item command="delete">下载报告</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <div slot="empty" id="empty-slot">
          <template v-if="!loading">
            <YTIcon :href="'#icon-zanwushuju'" style="font-size: 100px" />
          </template>
        </div>
      </el-table>
      <Page
        class="yt-page"
        :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
        :total="total"
        :disabled="loading"
        :current="pageNum + 1"
        :page-size-opts="[10, 20, 40, 100]"
        :page-size="pageSize"
        show-elevator
        show-sizer
        show-total
        @on-change="changePage"
        @on-page-size-change="changePageSize"
      />
    </div>
    <template v-if="showReportData && !reportLoading">
      <div class="yt-content">
        <h1 style="margin-bottom: 0">个人考试报告</h1>
        <el-row>
          <el-col :span="12" class="has-border long">
            <h2>基本信息</h2>
            <ul class="user-exam-info">
              <li>姓名: {{ reportData.userExamInfoVO.userName }}</li>
              <li>工号: {{ reportData.userExamInfoVO.jobNumber }}</li>
              <li>测评分数: {{ reportData.userExamInfoVO.score }}</li>
              <li>测评排名: {{ reportData.userExamInfoVO.ranking }}</li>
              <li>测评平均分: {{ reportData.userExamInfoVO.avgScore }}</li>
              <li>测评时间: {{ reportData.userExamInfoVO.submitTime }}</li>
              <li>测评用时: {{ reportData.userExamInfoVO.duration }}</li>
              <li>测评主题: {{ reportData.userExamInfoVO.examName }}</li>
            </ul>
          </el-col>
          <el-col :span="12" style="position: relative;">
            <h2 style="position:absolute;">测评得分情况</h2>
            <div ref="scoreBarChart" style="height: 190px;margin-top:30px"></div>
          </el-col>
        </el-row>
      </div>
      <div class="yt-content" style="padding:10px 20px 20px 10px">
        <h1>测评结果分析</h1>
        <el-row>
          <el-col :span="7" class="has-border">
            <div class="chart" style="height:396px" ref="skillOverallRadarChart"></div>
          </el-col>
          <el-col :span="9">
            <el-table :data="reportData.userExamAbilityRate" height="396px" class="yt-table col-table ">
              <el-table-column label="技能名称" prop="abilityName" align="center" show-overflow-tooltip />
              <el-table-column label="掌握率">
                <template slot-scope="scope">{{ $roundFloat(scope.row.userRate, 2) }}%</template>
              </el-table-column>
              <el-table-column label="平均掌握率">
                <template slot-scope="scope">{{ $roundFloat(scope.row.averageRate, 2) }}%</template>
              </el-table-column>
              <el-table-column label="差异" align="center">
                <template slot-scope="scope">{{ $roundFloat(scope.row.differenceRate, 2) }}%</template>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :span="8" class="has-border right" style="padding-left:10px">
            <div ref="distributionChart" style="height:396px"></div>
          </el-col>
        </el-row>
      </div>
      <div class="yt-content" style="background-color:#F8F8F8;padding:0">
        <div v-for="(item, index) in reportData.subSkillList" :key="index" class="subSkill-block">
          <h1 v-if="index === 0" style="margin-bottom: 15px;">详细技能分析</h1>
          <h3>{{ item.abilityName }}详细技能掌握情况</h3>
          <el-row>
            <el-col :span="7" class="has-border">
              <div :ref="'subSkillRadarChart-' + item.id" :style="{ height: item.tableHeight }"></div>
            </el-col>
            <el-col :span="9">
              <el-table :data="item.subAbilityRateVO" ref="subAbilityRateTable" class="yt-table col-table">
                <el-table-column label="技能名称" prop="abilityName" align="center" show-overflow-tooltip />
                <el-table-column label="描述" prop="description" align="center" show-overflow-tooltip />
                <el-table-column label="个人掌握率" align="center">
                  <template slot-scope="scope">{{ $roundFloat(scope.row.userRate, 2) }}%</template>
                </el-table-column>
                <el-table-column label="平均掌握率" align="center">
                  <template slot-scope="scope">{{ $roundFloat(scope.row.averageRate, 2) }}%</template>
                </el-table-column>
              </el-table>
            </el-col>
            <el-col :span="8" class="has-border right">
              <div :ref="'subSkillBarChart-' + item.id" :style="{ height: item.tableHeight }"></div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="yt-content">
        <h1>岗位匹配分析</h1>
        <div v-for="(item, index) in reportData.departmentMatchVOS" :key="index">
          <h2 style="margin-bottom: 20px">{{ item.departmentName }}岗位匹配情况分析</h2>
          <el-table :data="item.departmentRateEvaluationVOS" :span-method="arraySpanMethod" class="yt-table subSkill-table">
            <el-table-column label="技能" prop="mainSkillName" align="center" show-overflow-tooltip />
            <el-table-column label="子技能" prop="subSkillName" align="center" show-overflow-tooltip />
            <el-table-column label="权重" prop="weight" align="center" />
            <el-table-column :label="item.departmentName" width="250px" align="center">
              <template slot-scope="scope">
                <span
                  v-if="scope.row.rateName"
                  class="match-rate"
                  :style="{
                    color: scope.row.qualified === true ? '#4CE47A' : '#FF5050',
                    backgroundColor: scope.row.qualified === true ? '#EFFFF4' : '#FFEBEB'
                  }"
                >
                  {{ scope.row.rateName }}
                </span>
                <span v-else>未配置</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </template>
    <!--    上传个人报告-->
    <el-dialog class="uploadDialog" :visible.sync="IsImportExam" width="45%" title="上传个人考试报告">
      <p style="margin-bottom: 15px;">文件</p>
      <el-upload class="upload-demo" drag action="" :before-upload="uploadFile">
        <i class="el-icon-upload" />
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
      <Tag v-if="file" style="width: 192px" type="dot" closable color="primary" @on-close="file = null">{{ file.name }}</Tag>
      <div class="footer" style="display: flex;justify-content: flex-end;margin: 15px 0;">
        <el-button size="mini" @click="IsImportExam = false">取消</el-button>
        <el-button size="mini" :disabled="!canAble" type="primary" @click="uploadSerFile(url)">确定</el-button>
      </div>
    </el-dialog>
    <!--    发布重考-->
    <el-dialog class="deUsersModal" :visible.sync="retryModal" :close-on-click-modal="false" title="选择用户">
      <el-tabs v-model="activeIndex" style="margin-bottom: 20px;">
        <el-tab-pane label="参考人员" name="1">
          <el-table
            ref="allTable"
            :data="allData"
            style="width: 100%"
            row-key="userId"
            border
            height="500"
            v-if="activeIndex === '1'"
            @selection-change="handleSelect"
          >
            <el-table-column type="selection" :reserve-selection="true" width="60" align="center" />
            <el-table-column show-overflow-tooltip label="工号" align="center" prop="jobNumber" />
            <el-table-column show-overflow-tooltip label="岗位" align="center" prop="postName" />
            <el-table-column show-overflow-tooltip label="姓名" align="center" prop="realName" />
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="未及格人员" name="2">
          <el-table
            ref="failTable"
            :data="failData"
            style="width: 100%"
            row-key="userId"
            border
            height="500"
            v-if="activeIndex === '2'"
            @selection-change="handleSelect"
          >
            <el-table-column type="selection" :reserve-selection="true" width="60" align="center" />
            <el-table-column show-overflow-tooltip label="工号" align="center" prop="jobNumber" />
            <el-table-column show-overflow-tooltip label="岗位" align="center" prop="postName" />
            <el-table-column show-overflow-tooltip label="姓名" align="center" prop="realName" />
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="未参考人员" name="3">
          <el-table
            ref="unJoinTable"
            :data="unJoinData"
            style="width: 100%"
            row-key="userId"
            border
            height="500"
            v-if="activeIndex === '3'"
            @selection-change="handleSelect"
          >
            <el-table-column type="selection" :reserve-selection="true" width="60" align="center" />
            <el-table-column show-overflow-tooltip label="工号" align="center" prop="jobNumber" />
            <el-table-column show-overflow-tooltip label="岗位" align="center" prop="postName" />
            <el-table-column show-overflow-tooltip label="姓名" align="center" prop="realName" />
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="所有人员" name="4">
          <div style="height: 500px;display: flex;justify-content: space-between;">
            <div style="width: 43%;">
              <div class="container-title">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-jiagoufenxiao" />
                </svg>
                <span class="nowrap" style="margin-left: 5px;flex: 1 1 auto;">部门选择</span>
              </div>
              <div style="height: calc(100% - 50px);overflow: auto">
                <div style="text-align: left;margin: 20px 0 0 26px">
                  <!--          <span style="font-size: 14px;font-weight: 400;cursor: pointer" @click="getCurrentDepartmentId(chooseDepartmentId)">所有部门</span>-->
                </div>
                <el-tree
                  id="retry-tree-container"
                  v-if="treeData.length > 0"
                  :current-node-key="treeData[0].departmentId"
                  highlight-current
                  ref="tree"
                  node-key="departmentId"
                  @current-change="handleDepartmentId"
                  :props="defaultProps"
                  :data="treeData"
                  :expand-on-click-node="false"
                >
                  <div style="width: 100%;overflow: hidden" slot-scope="{ node }">
                    <el-tooltip effect="dark" :disabled="node.label.length < 8" :content="node.label" placement="bottom-start">
                      <div>{{ node.label }}</div>
                    </el-tooltip>
                  </div>
                </el-tree>
              </div>
            </div>
            <div style="width: 55%">
              <div style="display: flex;justify-content: flex-start;margin-bottom: 15px">
                <el-input
                  style="width: 300px;"
                  size="small"
                  placeholder="请输入姓名(回车搜索)"
                  v-model="retryKeyword"
                  @keyup.native.enter="search()"
                />
              </div>
              <div class="overStyle" style="max-height: 300px; overflow-y: auto">
                <el-table
                  ref="departmentTable"
                  :data="departmentTableData"
                  style="width: 100%"
                  row-key="userId"
                  border
                  v-if="activeIndex === '4'"
                  @selection-change="handleSelect"
                >
                  <el-table-column type="selection" :reserve-selection="true" width="60" align="center" />
                  <el-table-column show-overflow-tooltip label="工号" align="center" prop="jobNumber" />
                  <el-table-column show-overflow-tooltip label="岗位" align="center" prop="postName">
                    <template slot-scope="scope">{{ scope.row.postVO.postName }}</template>
                  </el-table-column>
                  <el-table-column show-overflow-tooltip label="姓名" align="center" prop="realName" />
                </el-table>
              </div>
              <div style="margin-top: 20px;display: flex;justify-content: space-between">
                <Page size="small" show-total @on-change="handlePageNum" :current="searchUser.pageParamForm.pageNumber + 1" :total="departmentTotal">
                </Page>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <el-button size="mini" @click="retryExam" type="primary">确定</el-button>
    </el-dialog>
    <PostReportDialog ref="postModal" />
  </div>
</template>

<script>
import exam from '@api/exam'
import fileApi from '@api/file'
import oss from '@api/oss'
import examReport from '@api/examReport'
import util from '@util/util'
import staff from '@api/staff'
import config from '@/config/config'
import PostReportDialog from '@components/common/dialog/PostReportDialog'
import YTIcon from '@components/common/YTIcon'
import enterEvaluateReportApi from '@api/enterEvaluateReport'
import barStackChart from '@components/manage/exam/list/reportCharts/barStackChart'
import radarChart from '@components/manage/exam/list/reportCharts/radarChart'
import barLineChart from '@components/manage/exam/list/reportCharts/barLineChart'
import barDoubleChart from '@components/manage/exam/list/reportCharts/barDoubleChart'
export default {
  name: 'personnelTestSituation.vue',
  components: { PostReportDialog, YTIcon },
  data() {
    return {
      loading: false,
      personalTestData: [],
      total: 0,
      pageNum: 0,
      pageSize: 10,
      failData: [],
      unJoinData: [],
      allData: [],
      treeData: [],
      defaultProps: {
        label: 'name',
        children: 'children'
      },
      IsImportExam: false,
      importIng: false,
      row: null,
      file: null,
      url: null,
      retryKeyword: '',
      retryDepartmentId: '',
      departmentTableData: [],
      userIds: [],
      departmentTotal: 0,
      retryPage: 0,
      searchUser: {
        pageParamForm: {
          pageNumber: 0,
          pageSize: 10
        },
        sortCriteria: {},
        userCriteria: {
          email: '',
          phone: '',
          realName: null,
          status: 0
        }
      },
      canAble: false,
      retryModal: false,
      activeIndex: '1',
      currentPage: 1,
      staffSelection: [], //被选中员工
      activeStaffId: '', //查看考试结果员工
      reportLoading: false,
      reportData: {
        userExamInfoVO: {}
      }
    }
  },
  computed: {
    showReportData() {
      return this.activeStaffId !== ''
    },
    containerHeight() {
      return this.showReportData ? 'auto' : 'calc(100% - 169px)'
    },
    tableHeight() {
      return this.showReportData ? '100%' : 'calc(100% - 32px)'
    }
  },
  created() {
    this.getDepartments()
    this.init()
  },
  mounted() {
    window.addEventListener('resize', this.reSizeCharts)
  },
  destroyed() {
    window.removeEventListener('resize', this.reSizeCharts)
  },
  methods: {
    //排序
    setSort(order) {
      switch (order.prop) {
        case 'startTime':
          this.init(order.order === 'ascending', 3)
          break
        case 'testTime':
          this.init(order.order === 'ascending', 2)
          break
        case 'totalMark':
          this.init(order.order === 'ascending', 1)
          break
        default:
          break
      }
    },
    //data
    init(isAsc, order) {
      //获取考试考生列表
      this.loading = true
      // isAsc=${isAsc}&order=${order}

      exam
        .getExamineesByExamId(this.$parent.examId, this.pageNum, this.pageSize, '', this.$parent.nameBySearch, isAsc || false, order || 1)
        .then(res => {
          this.total = res.res.total
          this.personalTestData = res.res.data.map(data => {
            return {
              idNumber: data['jobNumber'],
              userId: data['userId'],
              answerPaperStatus: data['answerPaperStatus'],
              answerPaperId: data['answerPaperId'],
              name: data['realName'],
              department: data['departmentName'],
              email: data['email'],
              phone: data['phone'],
              post: data['postName'],
              submitTime: data['submitTime'],
              startTime: data['startTime'],
              testTime: data['duration'] !== undefined ? data['duration'] : undefined,
              totalMark: data.hasOwnProperty('score') && data.score !== null && data.score !== undefined ? data['score'] : '—',
              options: null,
              isShowReport: data['isShowReport']
            }
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    reInit() {
      this.pageNum = 0
      this.init()
    },
    getDepartments() {
      // 所有部门
      this.treeData = []
      this.$store.dispatch('department/getDepartmentTree').then(res => {
        let tree = res.res
        this.treeData.push(tree)
        // this.retryDepartmentId = 0
        this.searchByKeyWord()
      })
    },
    search() {
      this.searchUser.pageParamForm.pageNumber = 0
      this.searchByKeyWord()
    },
    searchByKeyWord() {
      this.searchUser.departmentId = this.retryParentId === 0 ? '' : this.retryDepartmentId
      this.searchUser.keyword = this.retryKeyword
      this.searchUser.status = 0
      staff.searchStaffs(this.searchUser, this.searchUser.pageParamForm.pageNumber, this.searchUser.pageParamForm.pageSize).then(res => {
        this.departmentTableData = res.res.data
        this.departmentTotal = res.res.total
      })
    },
    handleDepartmentId(nodeData) {
      this.retryKeyword = ''
      this.retryDepartmentId = nodeData.departmentId
      this.searchByKeyWord()
    },
    getRetryExaminerByExam() {
      this.failData = []
      this.unJoinData = []
      exam.getExamineesByExamId(this.$parent.examId, 0, 9999, '', this.$parent.nameBySearch, false, 1).then(res => {
        this.allData = res.res.data
        this.allData.forEach(item => {
          if (item.hasOwnProperty('pass') && !item.pass) {
            this.failData.push(item)
          }
          if (!item.hasOwnProperty('answerPaperId')) {
            this.unJoinData.push(item)
          }
        })
      })
    },
    //events
    postModal(data) {
      // 打开岗位技能报告窗口
      this.$refs['postModal'].open(data.answerPaperId)
    },
    retryExam() {
      exam.makeRepeatExam(this.$parent.examId, this.userIds).then(res => {
        if (res.res) {
          this.$overSituationMsg('发布重考成功!', 'success', this)
          if (this.activeIndex === '1') {
            this.$refs.allTable.clearSelection()
          } else if (this.activeIndex === '2') {
            this.$refs.failTable.clearSelection()
          } else if (this.activeIndex === '3') {
            this.$refs.unJoinTable.clearSelection()
          } else if (this.activeIndex === '4') {
            this.$refs.departmentTable.clearSelection()
          }
          this.retryModal = false
          this.init()
        }
      })
    },
    handlePageNum(val) {
      this.searchUser.pageParamForm.pageNumber = val - 1
      this.retryPage = val - 1
      if (!this.retryKeyword.trim()) {
        this.searchUser.userCriteria.realName = this.retryKeyword
        staff.searchStaffs(this.searchUser, this.searchUser.pageParamForm.pageNumber, 10).then(res => {
          this.departmentTableData = res.res.data
        })
      } else {
        this.searchUser.pageParamForm.pageSize = 10
        this.searchByKeyWord()
      }
    },
    handleRowClick(row, column, event) {
      //表格行点击
      if (event.target.nodeName === 'SPAN') {
        return
      }
      this.activeStaffId = row.userId
      this.reportLoading = true
      this.$refs.personalTable.doLayout()
      let payload = { departmentIds: [], examId: this.$parent.examId, tagIds: [], userIds: [row.userId] }
      enterEvaluateReportApi
        .getPersonalReport(payload)
        .then(res => {
          if (res.code === 0) {
            this.reportLoading = false
            this.reportData = {
              ...res.res[0],
              scoreBarChart: null,
              skillRadarChart: null,
              distributionChart: null,
              userExamAbilityRate: res.res[0].userExamAbilityRate.map(item => {
                return {
                  subSkillRadarChart: null,
                  subSkillBarChart: null,
                  ...item
                }
              }) //测评结果分析
            }
            //详细技能分析
            this.reportData.subSkillList = this.reportData.userExamAbilityRate
              .filter(item => {
                return item.hasOwnProperty('subAbilityRateVO')
              })
              .map(item => {
                return {
                  id: this.$generateUUID(),
                  subSkillRadarChart: null,
                  subSkillBarChart: null,
                  tableHeight: '0px',
                  ...item
                }
              })
            //计算表格合并
            this.reportData.departmentMatchVOS.forEach(item => {
              let list = item.departmentRateEvaluationVOS
              let tmp = list.map(data => data.mainSkillName) //tmp每条目标数据mainSkillName
              item.departmentRateEvaluationVOS = list.map(row => {
                let len = list.filter(data => data.mainSkillName === row.mainSkillName).length
                let index = tmp.indexOf(row.mainSkillName)
                return { ...row, len: len, startIndex: index }
              })
            })
            this.$nextTick(() => {
              //题型
              this.reportData.scoreBarChart = this.initScoreBarChart()
              //详细技能分析行高
              this.reportData.subSkillList.forEach((item, index) => {
                item.tableHeight = `${this.computeRowHeight(this.$refs.subAbilityRateTable[index].$el.clientHeight)}px`
                this.$forceUpdate()
              })
              this.$nextTick(() => {
                //技能总体
                this.reportData.skillRadarChart = this.initSkillOverallRadarChart()
                //个人位于整体情况
                this.reportData.distributionChart = this.initDistributionChart()
                //详细技能
                this.reportData.subSkillList = this.reportData.subSkillList.map(item => {
                  if (item.hasOwnProperty('subAbilityRateVO')) {
                    item.subSkillRadarChart = this.initSkillRadarChart(item)
                    item.subSkillBarChart = this.initSkillBarChart(item)
                  }
                  return item
                })
              })
            })
          } else {
            this.activeStaffId = ''
            this.reportLoading = false
          }
        })
        .catch(() => {
          this.activeStaffId = ''
          this.reportLoading = false
        })
    },
    //utils
    computeRowHeight(height) {
      //计算chart高度
      return height < 80 ? 80 : height
    },
    initScoreBarChart() {
      //题型得分柱状图
      let barOption = this.$deepCopy(barStackChart.option)
      barOption.legend.itemWidth = 16
      barOption.legend.itemHeight = 8
      barOption.legend.top = 0
      barOption.legend.right = 50
      barOption.legend.itemGap = 12
      barOption.legend.textStyle.fontSize = '14px'
      barOption.grid = {
        top: 20,
        bottom: 21
      }
      barOption.yAxis.axisLabel.fontSize = '12px'
      barOption.xAxis.axisLabel.fontSize = '12px'
      barOption.xAxis.data = this.reportData.sectionScoreInfoVOList.map(item => {
        return item.sectionName
      })
      barOption.series[0].data = this.reportData.sectionScoreInfoVOList.map(item => {
        return item.score === 0 ? null : item.score
      })
      barOption.series[1].data = this.reportData.sectionScoreInfoVOList.map(item => {
        return item.lostScore === 0 ? null : item.lostScore
      })
      let scoreBarChart = this.$echarts.init(this.$refs.scoreBarChart, null, { renderer: 'svg' })
      scoreBarChart.setOption(barOption)
      return scoreBarChart
    },
    initSkillOverallRadarChart() {
      //技能整体掌握雷达图
      let radarOption = this.$deepCopy(radarChart.option)
      radarOption.legend.textStyle.fontSize = 10
      radarOption.legend.itemWidth = 6
      radarOption.legend.itemHeight = 6
      radarOption.legend.itemGap = 10
      radarOption.legend.orient = 'vertical'
      radarOption.legend.data.push({ icon: 'circle', name: '平均掌握率' })
      radarOption.radar[0].radius = 79
      radarOption.radar[0].axisName.fontSize = 12
      radarOption.radar[0].center = ['50%', '60%']
      radarOption.radar[0].axisName.formatter = value => {
        return value.length > 10 ? value.slice(0, 9) + '...' : value
      }
      radarOption.radar[0].indicator = this.reportData.userExamAbilityRate.map(item => {
        return { name: item.abilityName, max: 1 }
      })
      radarOption.series[0].symbolSize = 5
      radarOption.series[0].data[0].value = this.reportData.userExamAbilityRate.map(item => {
        return item.userRate
      })
      radarOption.series[0].data.push({
        name: '平均掌握率',
        value: this.reportData.userExamAbilityRate.map(item => {
          return item.averageRate
        }),
        itemStyle: {
          color: '#F16E57', //改变折线点的颜色
          lineStyle: {
            color: '#F16E57' //改变折线颜色
          }
        }
      })
      let skillOverallRadarChart = this.$echarts.init(this.$refs.skillOverallRadarChart, null, { devicePixelRatio: 2 })
      skillOverallRadarChart.setOption(radarOption)
      return skillOverallRadarChart
    },
    initDistributionChart() {
      //个人位于整体情况
      let lineOption = this.$deepCopy(barLineChart.option)
      lineOption.legend.data = ['比率']
      lineOption.legend.textStyle.fontSize = '12px'
      lineOption.legend.itemWidth = 10
      lineOption.legend.itemHeight = 2
      lineOption.grid = {
        top: 30,
        bottom: 21,
        left: 50,
        right: 10
      }
      lineOption.yAxis[0].axisLabel.fontSize = '12px'
      lineOption.yAxis[1].axisLabel.fontSize = '12px'
      lineOption.xAxis.axisLabel.fontSize = '12px'
      lineOption.xAxis.data = this.reportData.statisticsVOList.map(item => {
        return item.space
      })
      lineOption.xAxis.interval = 0
      lineOption.yAxis[0].max = 1
      lineOption.yAxis[0].axisLabel.formatter = (value, index) => {
        return this.$roundFloat(value, 0) + '%'
      }
      lineOption.yAxis = lineOption.yAxis.slice(0, 1)
      lineOption.series[0] = JSON.parse(JSON.stringify(lineOption.series[1]))
      lineOption.series[0].data = this.reportData.statisticsVOList.map(item => {
        return item.frequency
      })
      lineOption.series[0].yAxisIndex = 0
      lineOption.series[0].symbol = 'none'
      lineOption.series[1].name = '个人'
      lineOption.series[1].type = 'scatter'
      delete lineOption.series[1].yAxisIndex
      lineOption.series[1].data = [[this.reportData.personalStatisticsVO.space, this.reportData.personalStatisticsVO.frequency]]
      lineOption.series[1].color = '#FFC117'
      lineOption.series[1].symbolSize = 10
      lineOption.series[1].label = {
        show: true,
        fontSize: 12,
        position: 'top',
        color: 'rgba(0, 0, 0)',
        formatter: ({ data }) => {
          return `分数: ${this.reportData.userExamInfoVO.userScore} 比率: ${this.$roundFloat(data[1], 0)}%`
        }
      }
      let distributionChart = this.$echarts.init(this.$refs.distributionChart, null, { devicePixelRatio: 2.5 })
      distributionChart.setOption(lineOption)
      return distributionChart
    },
    initSkillRadarChart(skill) {
      //技能详细掌握雷达图
      let radarOption = this.$deepCopy(radarChart.option)
      if (skill.subAbilityRateVO.length < 3) {
        radarOption.radar[0].startAngle = 180
      }

      radarOption.legend.textStyle.fontSize = 10
      radarOption.legend.itemGap = 10
      radarOption.legend.itemWidth = 6
      radarOption.legend.itemHeight = 6
      radarOption.legend.orient = 'vertical'
      radarOption.legend.data.push({ icon: 'circle', name: '平均掌握率' })
      radarOption.radar[0].axisName.fontSize = '12px'
      radarOption.radar[0].radius = skill.subAbilityRateVO.length > 9 ? 79 : 45
      radarOption.radar[0].center = ['50%', '60%']
      radarOption.radar[0].axisName.formatter = value => {
        return value.length > 10 ? value.slice(0, 9) + '...' : value
      }
      radarOption.radar[0].indicator = skill.subAbilityRateVO.map(item => {
        return { name: item.abilityName, max: 1 }
      })
      radarOption.series[0].symbolSize = 5
      radarOption.series[0].data[0].value = skill.subAbilityRateVO.map(item => {
        return item.userRate
      })
      radarOption.series[0].data.push({
        name: '平均掌握率',
        value: skill.subAbilityRateVO.map(item => {
          return item.averageRate
        }),
        itemStyle: {
          color: '#F16E57', //改变折线点的颜色
          lineStyle: {
            color: '#F16E57' //改变折线颜色
          }
        }
      })
      let subSkillRadarChart = this.$echarts.init(this.$refs[`subSkillRadarChart-${skill.id}`][0], null, { devicePixelRatio: 2 })
      subSkillRadarChart.setOption(radarOption)
      return subSkillRadarChart
    },
    initSkillBarChart(skill) {
      //技能详细掌握柱状图
      let barOption = this.$deepCopy(barDoubleChart.option)
      barOption.grid = {
        top: 33,
        left: 70,
        right: 70,
        bottom: 5
      }
      barOption.legend.left = 'center'
      barOption.legend.itemWidth = 16
      barOption.legend.itemHeight = 8
      barOption.legend.itemGap = 12
      barOption.legend.textStyle.fontSize = 12
      barOption.legend.textStyle.padding = [0, 0, 0, 0]
      barOption.yAxis.data = skill.subAbilityRateVO.map(item => {
        return item.abilityName
      })
      barOption.yAxis.axisLabel.interval = 0
      barOption.yAxis.nameTextStyle.fontSize = 12
      barOption.yAxis.axisLabel.fontSize = 12
      barOption.yAxis.axisLabel.width = 55
      barOption.yAxis.axisLabel.overflow = 'truncate'
      barOption.yAxis.nameTextStyle.padding = [0, 5, 0, 0]
      barOption.yAxis.axisLabel.padding = [0, 0, 0, 0]
      barOption.series[0].barWidth = 10
      barOption.series[0].name = '掌握率'
      barOption.series[0].label.formatter = ({ data }) => {
        return parseInt(data) + '%'
      }
      barOption.series[0].data = skill.subAbilityRateVO.map(item => {
        return this.$roundFloat(item.userRate, 2)
      })
      barOption.series.push({
        name: '平均掌握率',
        type: 'bar',
        data: [],
        label: {
          show: true,
          formatter: function({ data }) {
            return parseInt(data) + '%'
          },
          position: 'right'
        },
        color: '#E8EAEE',
        barWidth: 10,
        barGap: 0
      })
      barOption.series[1].data = skill.subAbilityRateVO.map(item => {
        return this.$roundFloat(item.averageRate, 2)
      })
      let subSkillBarChart = this.$echarts.init(this.$refs[`subSkillBarChart-${skill.id}`][0])
      subSkillBarChart.setOption(barOption)
      return subSkillBarChart
    },
    reSizeCharts() {
      //批量处理echarts图尺寸
      this.reSizeChart(this.reportData.scoreBarChart)
      this.reSizeChart(this.reportData.skillRadarChart)
      this.reSizeChart(this.reportData.distributionChart)
      if (this.reportData.subSkillList.length > 0) {
        this.reportData.subSkillList.forEach(item => {
          this.reSizeChart(item.subSkillRadarChart)
          this.reSizeChart(item.subSkillBarChart)
        })
      }
    },
    reSizeChart(chart) {
      //判断是否存在chart并resize
      if (chart !== null) {
        chart.resize()
      }
    },
    arraySpanMethod({ row, rowIndex, columnIndex }) {
      //对于表格数据进行分组合并操作，UI组件回调函数
      if (columnIndex === 0) {
        //名称列 合并展示区
        const len = row.len
        if (rowIndex === row.startIndex) {
          //该名称在首位名称行
          return {
            rowspan: len,
            colspan: 1
          }
        } else
          return {
            //该名称不在首位名称行
            rowspan: 0,
            colspan: 0
          }
      }
    },
    handleSelect(val) {
      // 发布重考  选择
      this.userIds = []
      val.forEach(v => {
        this.userIds.push(v.userId)
      })
    },
    showRetry() {
      this.getRetryExaminerByExam()
      this.retryModal = true
    },
    async examPInfo() {
      // 导出员工信息
      // userType 来区分接口 0 内部 1 外部
      this.$parent.loading = true
      if (this.$parent.userType === 0) {
        const res = await exam.exportPeoInfo(this.$parent.examId)
        this.exportInterFace(res, '内部考试人员')
      } else {
        window.open(`${config.baseUrl}/exam/api/v2/exam/management/download/excel?examId=${this.$parent.examId}`)
      }
    },
    exportInterFace(data, tip) {
      util.exportExamPeoInfo(data, tip)
      this.$parent.loading = false
    },
    importAllReports() {
      // 导出员工报告
      this.$parent.loading = true
      examReport
        .getExamReportById(this.$parent.examId)
        .then(res => {
          if (res.res === '该考试无有效报告，无法生成') {
            return
          }
          // 创建隐藏的可下载链接
          let eleLink = document.createElement('a')
          eleLink.download = res.res.substring(res.res.lastIndexOf('/') + 1)
          eleLink.style.display = 'none'
          // 字符内容转变成blob地址
          eleLink.href = res.res
          // 触发点击
          document.body.appendChild(eleLink)
          eleLink.click()
          // 然后移除
          document.body.removeChild(eleLink)
        })
        .finally(() => {
          this.$parent.loading = false
        })
    },
    handleSee(row) {
      if (row.testTime === undefined) return
      // if (row.answerPaperStatus !== 6) return
      let res = this.$handleParams('examNewData')
      this.$router.push({
        name: 'newExamDetail',
        params: {
          tabLabel: this.$route.query.tabLabel,
          paperBankId: res.paperBankId,
          id: res.id,
          examId: this.$parent.examId,
          submitTime: row.submitTime,
          duration: row.duration,
          score: row.totalMark,
          userId: row.userId,
          exam: true,
          name: res.name,
          IsAnswerExam: true,
          IsExamLibrary: true
        }
      })
    },
    selectChange(val) {
      this.staffSelection = val.map(item => {
        return item.userId
      })
    },
    myReport(row) {
      // 个人报告
      if (row.testTime === undefined) return
      this.$emit('exportSingleReport', row.userId)
    },
    handleCommand(e, row) {
      if (e === 'delete') {
        // 下载个人报告
        let form = {
          examId: Number(this.$parent.examId),
          jobNumber: row.idNumber || null, // 工号
          reportType: 1, // 0 =》 试卷报告 1=》 个人报告
          userId: row.userId
        }
        exam.getExamReport(form).then(res => {
          if (res.res === '') {
            return this.$overSituationMsg('未导入报告或链接已失效', 'warning', this)
          }
          window.open(res.res)
          // window.location.href = res.res
        })
      } else {
        //  上传个人报告
        this.row = row
        this.IsImportExam = true
      }
    },
    uploadFile(file) {
      let _this = this
      this.file = file
      if (window.uploadUrl) {
        // 局域网
        let formData = new FormData()
        formData.append('file', file)
        fileApi.uploadServeFile(formData).then(res => {
          if (res.code === 0) {
            this.url = res.res
            this.canAble = true
          }
        })
      } else {
        // oss.
        oss.upType().then(res => {
          if (res.code === 0) {
            oss.getQuestionFileToken(file.name).then(data => {
              let fData = data.res
              const formData = new FormData()
              formData.append('key', fData.dir)
              formData.append('OSSAccessKeyId', fData.accessId)
              formData.append('policy', fData.policy)
              formData.append('Signature', fData.signature)
              formData.append('file', file)
              fetch(`https://${fData.host}`, {
                method: 'POST',
                body: formData
              }).then(() => {
                _this.url = `https://${fData.host}/${fData.dir}`
                _this.canAble = true
              })
            })
          }
        })
      }
      return false
    },
    uploadSerFile(file) {
      let form = {
        answerPaperId: this.row.answerPaperId,
        examId: this.$parent.examId,
        jobNumber: this.row.jobNumber || null, // 工号
        reportType: 1, // 0 =》 试卷报告 1=》 个人报告
        reportUrl: file,
        userId: this.row.userId
      }
      exam.importExam(form).then(res => {
        if (res.code === 0) {
          this.$overSituationMsg('成功', 'success', this)
          this.IsImportExam = false
          this.init()
        }
      })
    },
    changePage(pageNum) {
      this.pageNum = pageNum - 1
      this.init()
    },
    changePageSize(pageSize) {
      this.pageNum = 0
      this.pageSize = pageSize
      this.init()
    }
  }
}
</script>

<style scoped lang="less">
@import '~@/theme/variables';
.yt-container {
  margin-top: 20px;
  padding: 10px;
  background-color: #fff;
  height: auto;
  min-height: calc(100% - 175px);
}
.yt-content {
  padding: 10px;
  margin-bottom: 10px;
  .subSkill-block {
    background-color: #ffffff;
    margin-bottom: 10px;
    padding: 20px 10px 20px 10px;
  }
  .yt-table {
    ::v-deep .el-table__header thead th {
      height: 36px;
    }
    ::v-deep .el-table__row {
      height: 36px;
    }
    &.subSkill-table {
      ::v-deep .el-table__row {
        height: 49px;
      }
    }
    &.personal-table {
      ::v-deep .el-table__header thead th {
        height: 46px;
      }
      ::v-deep .el-table__row {
        height: 49px;
      }
    }
  }
  ::v-deep .el-table td,
  .el-table th {
    padding: 6px 0;
  }

  .has-border {
    position: relative;
    &.long {
      &:after {
        height: 153px;
        top: 60px;
      }
    }
    &.right {
      &:after {
        left: 0;
      }
    }
    &:after {
      content: '';
      width: 1px;
      height: calc(100% - 50px);
      background-color: #e2e4e8;
      position: absolute;
      right: 0;
      top: 34px;
      margin: auto 0;
    }
  }
  .col-table {
    width: calc(100% - 40px);
    margin: 0 20px;
  }
  h1 {
    font-size: @default;
    line-height: 22px;
    margin-bottom: 30px;
  }
  h2 {
    margin-top: 20px;
    margin-left: 10px;
    font-size: @medium;
    line-height: 20px;
  }
  h3 {
    padding-left: 20px;
    margin-bottom: 20px;
    font-size: @medium;
    color: #448bff;
    position: relative;
    &:before {
      content: '';
      width: 3px;
      height: 12px;
      background: #448bff;
      position: absolute;
      left: 10px;
      border-radius: 10px;
    }
  }
  .user-exam-info {
    .flexStyle(flex, flex-start);
    flex-wrap: wrap;
    margin-top: -4px;
    li {
      .ellipsis();
      width: 50%;
      line-height: 20px;
      padding-left: 50px;
      margin-top: 24px;
      font-size: @medium;
    }
  }
  .match-rate {
    display: inline-block;
    height: 32px;
    line-height: 32px;
    border-radius: 19px;
    padding: 0 22px;
  }
}
#empty-slot {
  height: 200px;
  line-height: 250px;
}
.tool-button {
  margin-right: 10px;
  font-size: 12px;
  color: #448bff;
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid #2276ff;
  }
  &:last-of-type {
    margin-right: 0;
  }
}
.pagination {
  .flexStyle(flex, center, center);
}
</style>
