import config from '../config/config'
import { axios } from './index'

const examResultUrl = config.baseUrl + '/exam/api/v2/exam'

export default {
  //考试结果-全部信息
  getAllSimple(payload) {
    return axios.post(`${examResultUrl}/all/simple?examId=${payload.examId}`, payload)
  }
}
