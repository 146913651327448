<template>
  <el-dialog class="post-dialog" destroy-on-close :visible.sync="value" @close="closed">
    <div class="print-button back-top" @click="backTop">回到顶部</div>
    <div
      class="print-button"
      @click="loading ? '' : exportPdf()"
      v-loading="loading"
      element-loading-background="rgba(0, 0, 0, 0)"
      element-loading-text="正在下载"
    >
      {{ loading ? '' : '下载报告' }}
    </div>
    <div id="abilityReport">
      <div class="report-header">
        <img :src="require('@assets/logo/report-logo.png')" height="38px" alt="" />
        <div class="yt-flex-layout">
          https://enter.yuantusoft.com/home
          <img :src="require('@assets/logo/tencent-cloud-qrcode.png')" height="44px" width="44px" alt="" style="margin-left: 10px" />
        </div>
      </div>
      <h3 class="report-title">个人岗位技能分析报告</h3>

      <!--   测评结果概览   -->
      <div class="test-result-info theme-data">
        <div class="title">一、测评结果概览</div>
        <div class="secondary-heading">1.1 基本信息</div>
        <div class="table-style yt-flex-layout">
          <div class="row-one-div" v-for="(b, bIndex) in basicInfo" :key="bIndex">
            <div class="one">
              <span>{{ b['key'] }}:</span><span style="margin-left: 15px">{{ b['value'] }}</span>
            </div>
          </div>
        </div>

        <div class="secondary-heading">1.2 测评得分情况</div>
        <div ref="scoreEcharts" class="columnar-s" />

        <div class="secondary-heading">1.3 技能掌握率</div>
        <el-table :data="abilityData" width="100%" class="yt-table">
          <el-table-column label="技能分类" prop="abilityName" />
          <el-table-column label="本次测评结果" prop="grasp" />
          <el-table-column label="该方向平均掌握率" prop="avgGrasp" />
          <el-table-column label="在该方向内排名" prop="ranking" />
        </el-table>
      </div>

      <!--   详细技能分析   -->
      <div class="ability-analysis theme-data">
        <div class="title">二、详细技能分析</div>
        <div>
          <p class="h-tip">
            根据本次测评结果，您在 {{ bigCategory }} 个大类 {{ smallAbility }} 项技能中共有 {{ qualifiedQuantity }} 项符合岗位要求。
          </p>
          <div ref="radar-ability" class="radar-ab" />
        </div>
        <div class="secondary-heading">2.1 编程基础</div>
        <div>
          <div ref="radar-baseCode" class="radar-abs" />
          <el-table :data="baseCodeTable" width="100%" class="yt-table">
            <el-table-column label="技能名称" prop="abilityName" width="180" />
            <el-table-column label="描述" prop="description" />
            <el-table-column label="个人掌握率" prop="grasp" width="125" />
            <el-table-column label="方向掌握率" prop="avgGrasp" width="125" />
            <el-table-column label="岗位模型要求" prop="postAskFor" width="145" />
          </el-table>
        </div>

        <div class="secondary-heading">2.2 专业基础</div>
        <div>
          <div ref="radar-specialty" class="radar-abs" />
          <el-table :data="specialtyTable" width="100%" class="yt-table">
            <el-table-column label="技能名称" prop="abilityName" width="150" />
            <el-table-column label="描述" prop="description" />
            <el-table-column label="个人掌握率" prop="grasp" width="125" />
            <el-table-column label="方向掌握率" prop="avgGrasp" width="125" />
            <el-table-column label="岗位模型要求" prop="postAskFor" width="145" />
          </el-table>
        </div>

        <div class="secondary-heading">2.3 工程能力</div>
        <div>
          <div ref="radar-project" class="radar-abs" />
          <el-table :data="projectTable" width="100%" class="yt-table">
            <el-table-column label="技能名称" prop="abilityName" width="150" />
            <el-table-column label="描述" prop="description" />
            <el-table-column label="个人掌握率" prop="grasp" width="125" />
            <el-table-column label="方向掌握率" prop="avgGrasp" width="125" />
            <el-table-column label="岗位模型要求" prop="postAskFor" width="145" />
          </el-table>
        </div>
        <div class="secondary-heading">2.4 编程操作</div>
        <div>
          <el-table :data="codeTable" width="100%" class="yt-table">
            <el-table-column label="技能名称" prop="abilityName" />
            <el-table-column label="描述" prop="description" />
            <el-table-column label="本次测评掌握率" prop="grasp" />
            <el-table-column label="岗位要求掌握率" prop="postAskFor" />
          </el-table>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { columnarJS, infoJS, abilityTable, abilityRadar } from '@util/charts/echartsOptions'
import examApi from '@api/exam'
import { pdfD2 } from '@util/pdfDown2'
export default {
  name: 'PostReportDialog',
  data() {
    return {
      loading: false,
      value: false,
      myChart1: null,
      myChart2: null,
      myChart3: null,
      myChart4: null,
      myChart5: null,
      myChart6: null,
      qualifiedQuantity: null, // 符合要求数量
      bigCategory: null,
      smallAbility: null, // 大类中的技能数量
      legendList: ['个人掌握率', '研发方向平均掌握度', '岗位模型要求'],
      baseCodeTable: [], // 编程基础
      specialtyTable: [], // 专业基础
      projectTable: [], // 工程能力
      basicInfo: [],
      scoreEchart: [],
      abilityData: [],
      abilityRa: [], // 详细技能分析雷达图
      baseCodeRa: [], // 编程基础雷达图
      specialtyRa: null, // 专业基础雷达图
      projectRa: null, // 工程能力雷达图
      codeTable: []
    }
  },
  methods: {
    closed() {
      this.value = false
      Object.assign(this.$data, this.$options.data())
    },
    backTop() {
      document.querySelector('.post-dialog').scrollTop = 0
    },
    exportPdf() {
      this.loading = true
      pdfD2(document.getElementById('abilityReport'), `岗位技能报告(${this.basicInfo[0].value}${this.basicInfo[1].value}).pdf`)
        .then(() => {
          this.$message.success('导出成功!')
        })
        .catch(() => {
          this.$message.error('导出失败!')
        })
        .finally(() => {
          this.loading = false
        })
    },
    open(id) {
      this.value = true
      this.basicInfo = this.$deepCopy(infoJS)
      this.scoreEchart = this.$deepCopy(columnarJS)
      this.abilityData = this.$deepCopy(abilityTable)
      this.abilityRa = this.$deepCopy(abilityRadar) // 详细技能分析雷达图
      this.baseCodeRa = this.$deepCopy(abilityRadar) // 编程基础雷达图
      examApi.abilityReport(id).then(res => {
        // basicInfo 基本信息
        // abilityGrasps 技能掌握率 表格数据
        // abilityAnalyseList 雷达图相关信息
        // sectionScoreInfos 得分柱状图
        const data = JSON.parse(res.res)
        this.bigCategory = data.abilityAnalyseList.length // 几大类
        this.smallAbility = data.abilityAnalyseList.reduce((pre, next) => {
          if (next.subAbilityAnalyseList.length) {
            pre += next.subAbilityAnalyseList.length
          } else {
            next.abilityName === '编程操作' ? (pre += 1) : false
          }
          return pre
        }, 0)
        this.initBseData(data['basicInfo'])
        this.initScoreData(data['sectionScoreInfos'])
        this.abilityGrasps(data['abilityGrasps'])
        this.initAbilityRandar(data['abilityAnalyseList'])
        this.projectAbility(data['abilityAnalyseList'])
        this.initCodeTable(data['abilityAnalyseList'])
      })
    },
    abilityGrasps(info) {
      // 技能掌握率
      let temp = ['编程基础', '专业基础', '工程能力', '编程操作']
      this.abilityData = []
      for (let i = 0; i < temp.length; i++) {
        for (const item of info) {
          if (item.abilityName === temp[i]) {
            this.abilityData.push({
              abilityName: item.abilityName,
              grasp: item.grasp === 0 ? 0 : `${this.$roundFloat(item.grasp, 2)} %`,
              avgGrasp: item.avgGrasp === 0 ? 0 : `${this.$roundFloat(item.avgGrasp, 2)} %`,
              ranking: item.ranking
            })
          }
        }
      }
    },
    initBseData(info) {
      // 基本信息
      for (const iterator of this.basicInfo) {
        if (iterator.name === 'duration') {
          iterator.value = `${info[iterator.name]} 分钟`
        } else if (iterator.name.includes('core')) {
          iterator.value = `${info[iterator.name]} 分`
        } else {
          iterator.value = info[iterator.name]
        }
      }
    },
    initScoreData(info) {
      // 得分柱状图
      let series = this.scoreEchart.series
      this.scoreEchart.xAxis.data = info.map(info => {
        for (let i = 0; i < series.length; i++) {
          if (series[i].name === '得分') {
            series[i].data.push(info.score !== 0 ? info.score : '')
          } else {
            series[i].data.push(info.lostScore !== 0 ? info.lostScore : '')
          }
        }
        return info.sectionName
      })
      this.myChart1 = this.$echarts.init(this.$refs['scoreEcharts'])
      this.$nextTick(() => {
        this.myChart1.setOption(this.scoreEchart)
      })
    },
    initAbilityRandar(arr) {
      let num = 0
      // 这里是雷达图四个扇形区域的数据  固定值
      let defaultD = {
        type: 'pie',
        z: 1,
        startAngle: 75, //起始角度
        radius: '230',
        data: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        },
        label: {
          normal: {
            position: 'inner'
          }
        },
        labelLine: {
          normal: {
            show: false
          }
        },
        itemStyle: {
          normal: {
            // borderWidth: 2, // 间距的宽度
            borderColor: '#fff', //背景色
            color: function(params) {
              var colorlist = ['#f8ebb6', '#c5d6e1', '#f4d3c2', '#ccd7bf']
              return colorlist[params.dataIndex]
            }
          }
        }
      }
      defaultD.data = arr
        .map(item => {
          if (item.subAbilityAnalyseList.length) {
            for (const params of item.subAbilityAnalyseList) {
              if (params.isTrue) {
                num += 1
              }
            }
          } else {
            item.isTrue ? (num += 1) : false
          }
          return {
            value: item.subAbilityAnalyseList.length === 1 || item.subAbilityAnalyseList.length === 0 ? 2 : item.subAbilityAnalyseList.length
          }
        })
        .reverse()
      this.qualifiedQuantity = num || 0
      this.abilityRa.series[0].data = []
      this.abilityRa.radar.indicator = []
      this.abilityRa.radar.radius = 230
      this.abilityRa.radar.center = ['50%', '50%']
      this.abilityRa.color = ['#c63131', '#2b62a9', '#757f75']
      this.abilityRa.legend = {
        top: 65,
        data: ['个人掌握率', `${this.basicInfo[5].value}方向平均掌握度`, '岗位模型要求']
      }
      this.abilityRa.title.text = `${this.basicInfo[5].value}方向岗位匹配度`
      this.abilityRa.radar.splitNumber = 10 // 环数
      Object.assign(this.abilityRa.series[0].itemStyle, {
        color: function(params) {
          let colorlist = ['#c63131', '#2b62a9', '#757f75']
          return colorlist[params.dataIndex]
        }
      })
      // 这里是模拟的加数据
      let b = [
        { value: [], key: 'grasp', name: '个人掌握率', symbol: 'none' },
        { value: [], key: 'avgGrasp', name: `${this.basicInfo[5].value}方向平均掌握度`, symbol: 'none' },
        { value: [], key: 'postAskFor', name: '岗位模型要求', symbol: 'none' }
      ]
      let subData = []
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].subAbilityAnalyseList.length) {
          for (const sub of arr[i].subAbilityAnalyseList) {
            this.abilityRa.radar.indicator.push({
              name: sub.abilityName,
              color: '#000',
              max: 100,
              axisLabel: { show: true, fontsize: 12, showMinLabel: true }
            })
            subData.push(sub)
          }
        } else {
          this.abilityRa.radar.indicator.push({
            name: arr[i].abilityName,
            color: '#000',
            max: 100,
            axisLabel: { show: true, fontsize: 12, showMinLabel: true }
          })
          subData.push({
            abilityId: arr[i].abilityId,
            abilityName: arr[i].abilityName,
            avgGrasp: arr[i].avgGrasp,
            description: '',
            grasp: arr[i].grasp,
            isTrue: arr[i].isTrue,
            postAskFor: arr[i].postAskFor
          })
        }
      }
      for (let j = 0; j < b.length; j++) {
        for (let k = 0; k < subData.length; k++) {
          if (b[j].key !== 'postAskFor') {
            b[j].value.push(subData[k][b[j].key] * 100)
          } else {
            b[j].value.push(subData[k][b[j].key])
          }
        }
      }
      this.abilityRa.series.push(defaultD)
      this.abilityRa.series[0].data = b.map(bDate => {
        return bDate
      })
      this.myChart2 = this.$echarts.init(this.$refs['radar-ability'])
      this.myChart2.setOption(this.abilityRa)
      this.initBaseCodeRadar(arr)
    },
    initBaseCodeRadar(arr) {
      // 编程基础雷达图
      let baseData = arr
        .filter(item => {
          return item.abilityName.indexOf('编程基础') !== -1
        })
        .map(data => {
          return data.subAbilityAnalyseList
        })[0]
      this.baseCodeRa.title.text = '编程基础匹配度'
      this.baseCodeRa.backgroundColor = '#f5dbcd' //背景色
      this.baseCodeRa.color = ['#c63131', '#2b62a9', '#757f75']
      this.baseCodeRa.legend = {
        left: 'center',
        top: 35,
        data: ['个人掌握率', `${this.basicInfo[5].value}方向平均掌握度`, '岗位模型要求']
      }
      this.baseCodeRa.radar.center = ['50%', '55%']
      this.baseCodeRa.radar.splitNumber = 5 // 环数
      this.baseCodeRa.radar.radius = 180

      this.baseCodeRa.radar.indicator = baseData.map(b => {
        return {
          name: b.abilityName,
          color: '#000',
          max: 100,
          axisLabel: { show: true, fontsize: 12, showMinLabel: true }
        }
      })
      this.baseCodeRa.series[0].data = [
        { value: [], key: 'grasp', name: '个人掌握率', symbol: 'none' },
        { value: [], key: 'avgGrasp', name: `${this.basicInfo[5].value}方向平均掌握度`, symbol: 'none' },
        { value: [], key: 'postAskFor', name: '岗位模型要求', symbol: 'none' }
      ]
      this.baseCodeTable = baseData.map(b => {
        for (const series of this.baseCodeRa.series[0].data) {
          if (series.key !== 'postAskFor') {
            series.value.push(b[series.key] * 100)
          } else {
            series.value.push(b[series.key])
          }
        }
        return {
          abilityName: b.abilityName,
          avgGrasp: b.avgGrasp === 0 ? 0 : `${this.$roundFloat(b.avgGrasp, 2)} %`,
          description: b.description,
          grasp: b.grasp === 0 ? 0 : `${this.$roundFloat(b.grasp, 2)} %`,
          postAskFor: b.postAskFor ? `${b.postAskFor} %` : '0%'
        }
      })
      this.myChart3 = this.$echarts.init(this.$refs['radar-baseCode'])
      this.myChart3.setOption(this.baseCodeRa)
      this.specialtyAbility(arr)
    },
    specialtyAbility(arr) {
      // 专业基础雷达图
      let baseData = arr
        .filter(item => {
          return item.abilityName === '专业基础'
        })
        .map(data => {
          return data.subAbilityAnalyseList
        })[0]
      this.specialtyRa = JSON.parse(JSON.stringify(this.baseCodeRa))
      this.specialtyRa.title.text = '专业基础匹配度'
      this.specialtyRa.backgroundColor = '#d5decb' //背景色
      this.specialtyRa.color = ['#c63131', '#2b62a9', '#757f75']
      this.specialtyRa.radar.indicator = baseData.map(b => {
        return {
          name: b.abilityName,
          color: '#000',
          max: 100,
          axisLabel: { show: true, fontsize: 12, showMinLabel: true }
        }
      })
      this.specialtyRa.series[0].data = [
        { value: [], key: 'grasp', name: '个人掌握率', symbol: 'none' },
        { value: [], key: 'avgGrasp', name: `${this.basicInfo[5].value}方向平均掌握度`, symbol: 'none' },
        { value: [], key: 'postAskFor', name: '岗位模型要求', symbol: 'none' }
      ]
      this.specialtyTable = baseData.map(b => {
        for (const series of this.specialtyRa.series[0].data) {
          if (series.key !== 'postAskFor') {
            series.value.push(b[series.key] * 100)
          } else {
            series.value.push(b[series.key])
          }
        }
        return {
          abilityName: b.abilityName,
          avgGrasp: b.avgGrasp === 0 ? 0 : `${this.$roundFloat(b.avgGrasp, 2)} %`,
          description: b.description,
          grasp: b.grasp === 0 ? `0%` : `${this.$roundFloat(b.grasp, 2)} %`,
          postAskFor: b.postAskFor ? `${b.postAskFor} %` : '0%'
        }
      })
      this.myChart4 = this.$echarts.init(this.$refs['radar-specialty'])
      this.myChart4.setOption(this.specialtyRa)
      this.projectAbility(arr)
    },
    projectAbility(arr) {
      // 工程能力
      let baseData = arr
        .filter(item => {
          return item.abilityName === '工程能力'
        })
        .map(data => {
          return data.subAbilityAnalyseList
        })[0]
      this.projectRa = JSON.parse(JSON.stringify(this.baseCodeRa))
      this.projectRa.title.text = '工程能力匹配度'
      this.projectRa.backgroundColor = '#c5d6e1' //背景色
      this.projectRa.color = ['#c63131', '#2b62a9', '#757f75']
      this.projectRa.radar.indicator = baseData.map(b => {
        return {
          name: b.abilityName,
          color: '#000',
          max: 100,
          axisLabel: { show: true, fontsize: 12, showMinLabel: true }
        }
      })
      this.projectRa.series[0].data = [
        { value: [], key: 'grasp', name: '个人掌握率', symbol: 'none' },
        { value: [], key: 'avgGrasp', name: `${this.basicInfo[5].value}方向平均掌握度`, symbol: 'none' },
        { value: [], key: 'postAskFor', name: '岗位模型要求', symbol: 'none' }
      ]
      if (!baseData.length) {
        this.projectTable = []
        return
      }
      this.projectTable = baseData.map(b => {
        for (const series of this.projectRa.series[0].data) {
          if (series.key !== 'postAskFor') {
            series.value.push(b[series.key] * 100)
          } else {
            series.value.push(b[series.key])
          }
        }
        return {
          abilityName: b.abilityName,
          avgGrasp: b.avgGrasp === 0 ? 0 : `${this.$roundFloat(b.avgGrasp, 2)} %`,
          description: b.description,
          grasp: b.grasp === 0 ? 0 : `${this.$roundFloat(b.grasp, 2)} %`,
          postAskFor: b.postAskFor ? `${b.postAskFor} %` : '0%'
        }
      })
      this.myChart5 = this.$echarts.init(this.$refs['radar-project'])
      this.myChart5.setOption(this.projectRa)
    },
    initCodeTable(arr) {
      for (const a of arr) {
        if (a.abilityName === '编程操作') {
          this.codeTable.push({
            abilityName: a.abilityName,
            avgGrasp: a.avgGrasp === 0 ? 0 : `${this.$roundFloat(a.avgGrasp, 2)} %`,
            description: a.description,
            grasp: a.grasp ? `${this.$roundFloat(a.grasp, 2)} %` : 0,
            postAskFor: a.postAskFor ? `${a.postAskFor} %` : '0%'
          })
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/variables.less';
@import '~@/theme/pdfLoading.less';
.yt-table {
  width: calc(100% - 40px);
  margin: 0 auto;
  border: 1px solid #d9d9d9;
  border-bottom: none;
  ::v-deep .el-table__header-wrapper {
    border-radius: 0;
  }
  ::v-deep .el-table__header {
    thead {
      th {
        .font(20px, bold, #000);
        height: 50px;
        background-color: #f0f0f0 !important;
      }
    }
  }
  ::v-deep .el-table__row {
    td {
      .font(20px, Regular, #000);
      border-bottom-color: #d9d9d9;
    }
  }
  ::v-deep .cell {
    font-size: 20px !important;
  }
  ::v-deep .el-table__empty-block {
    border-bottom: 1px solid #d9d9d9;
  }
}
.post-dialog {
  .font(14px, Regular, #000);
  .report-header {
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    div {
      align-items: center;
    }
  }
  #abilityReport {
    padding: 120px 186px;
    background-image: url('~@assets/watermark.png');
  }
  .report-title {
    display: flex;
    justify-content: center;
    margin: 30px 0;
    .font(36px, bold, #000);
  }
  .test-result-info {
    text-align: left;
    .table-style {
      padding-left: 60px;
      flex-wrap: wrap;
      .row-one-div {
        margin-top: 30px;
        font-size: 24px;
        width: 50%;
      }
    }
    .columnar-s {
      width: 90%;
      height: 300px;
      margin: 0 auto;
    }
  }
  .ability-analysis {
    text-align: left;
    position: relative;
    .legend-style {
      position: absolute;
      top: 170px;
      left: 50%;
      transform: translateX(-50%);
    }
    .h-tip {
      margin: 20px 0;
      font-size: 20px;
    }
    .radar-ab {
      width: 90%;
      height: 750px;
      margin: 0 auto;
    }
    .radar-p {
      width: 90%;
      height: 750px;
      margin: 0 auto;
    }
    .radar-abs {
      width: 90%;
      height: 500px;
      margin: 0 auto;
      margin-bottom: 10px;
    }
  }
  .theme-data {
    &:not(:last-child) {
      margin-bottom: 100px;
    }
    .title {
      .font(30px, bold, #000);
    }
    .secondary-heading {
      .font(28px, bold, #000);
      margin: 40px 0 20px 0;
    }
  }
  .print-button {
    position: fixed;
    top: 500px;
    z-index: 1;
    right: 100px;
    width: 140px;
    padding: 25px 40px;
    height: 140px;
    text-align: center;
    color: #ffffff;
    font-size: 30px;
    background: #448bff;
    box-shadow: 0 3px 6px rgba(60, 100, 209, 0.5);
    border-radius: 100px;
    cursor: pointer;
  }
  .back-top {
    background-color: #ff6060;
    box-shadow: 0 3px 6px rgba(255, 159, 159, 0.5);
    top: 330px;
  }
  ::v-deep {
    .el-dialog {
      margin-top: 0vh !important;
      border-radius: 6px;
      min-width: 1240px;
      max-width: 1240px;
    }
  }
}
</style>
