import config from '../config/config'
import { axios } from './index'

const externalUrl = config.baseUrl + '/user/api/v2/external'

export default {
  //新增外部人员
  addExternal(form) {
    return axios.post(`${externalUrl}/add`, form)
  },
  //根据考试删除外部人员
  deleteExternalId(examId) {
    return axios.post(`${externalUrl}/delete/by/exam?examId=${examId}`)
  },
  /**
   * @param payload  body参数
   * @param examId params中的参数
   * @return {AxiosPromise<any>}
   */
  //删除外部人员
  deleteExternal(payload, examId) {
    return axios.post(`${externalUrl}/delete/external?examId=${examId}`, payload)
  },
  //获取指定考试的外部人员列表
  getExternalUser(payload) {
    return axios.get(
      `${externalUrl}/exam/users?sort=${payload.sort}&page=${payload.page}&size=${payload.size}&examId=${payload.examId}&name=${payload.name}`
    )
  },
  //导入外部人员
  uploadExternal(payload, formData) {
    return axios.post(`${externalUrl}/upload/employees?examId=${payload}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
  },
  //下载外部人员模板
  downloadExternal() {
    return axios.post(`${externalUrl}/download`, null, {
      responseType: 'blob'
    })
  },
  //GET /api/v2/external/user/type获取人员类型
  getExternalType(payload) {
    return axios.get(`${externalUrl}/user/type?examId=${payload}`)
  }
}
