import config from '../config/config'
import { axios } from './index'

let organizationUrl = config.baseUrl + '/user/api/v1/org'
export default {
  //发送添加企业的验证码-使用要生成企业的手机号
  sendSmsCode(phone) {
    return axios.post(`${organizationUrl}/sms/code/send?phone=${phone}`, null, {
      headers: {
        Authorization: ''
      }
    })
  },
  // 组织考试限制
  organizationByExamLimit() {
    return axios.get(`${organizationUrl}/get/limit/simple`)
  }
}
