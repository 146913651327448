import config from '../config/config'
import { axios } from './index'

let baseUrl = config.baseUrl + '/knowledge/api/v2/post'

export default {
  //判断是否有代码题
  judgeCodeQuestion(payload) {
    return axios.get(`${baseUrl}/judge/code/question?postId=${payload}`)
  },
  //获取岗位的主技能子技能详情
  getPostSkill(payload) {
    return axios.get(`${baseUrl}/get/post-skill?postId=${payload}`)
  },
  //导出岗位的主技能子技能详情
  downloadPostSkill(payload) {
    return axios.get(`${baseUrl}/download/post-skill?postId=${payload}`, {
      responseType: 'blob'
    })
  },
  //复制岗位
  copyPost(payload) {
    return axios.get(`${baseUrl}/copy?srcPostId=${payload.srcPostId}&postName=${payload.postName}`)
  },
  //添加岗位技能
  addPostSkill(payload) {
    return axios.post(`${baseUrl}/add/post-skill`, payload)
  },
  //编辑岗位技能
  editPostSkill(payload) {
    return axios.post(`${baseUrl}/edit/post-skill`, payload)
  },
  //删除岗位技能
  deletePostSkill(payload) {
    return axios.post(`${baseUrl}/delete/post-skill?postSkillId=${payload}`)
  },
  //获取岗位下技能和技能题目数量接口
  getPostAbilityQuestion() {
    return axios.get(`${baseUrl}/get/post/skill/question`)
  },
  //获取岗位模型关联指数
  getPostRelevance() {
    return axios.get(`${baseUrl}/get/relevance`)
  },
  //获取岗位测评次数
  getPostExamCount() {
    return axios.get(`${baseUrl}/get/post/exam/count`)
  },
  //按条件查询企业岗位模型信息
  searchPostModel(payload) {
    return axios.post(`${baseUrl}/search/organization/post`, payload)
  },
  //获取岗位包含主技能和子技能的详情信息
  getPostSkillDetail(payload) {
    return axios.get(`${baseUrl}/get/post-skill/detail?postId=${payload}`)
  },
  //修改岗位模型信息
  updatePostInfo(payload) {
    return axios.post(`${baseUrl}/modify/post/info`, payload)
  },
  //获取岗位主技能列表
  getMainSkillList(payload) {
    return axios.get(`${baseUrl}/get/main/skill/list?postId=${payload}`)
  },
  //获取岗位主技能评级及基本信息
  getMainSkillInfo(payload) {
    return axios.get(`${baseUrl}/get/main/skill/basic?postSkillId=${payload}`)
  },
  //修改岗位主技能评级及基本信息
  updateMainSkillInfo(payload) {
    return axios.post(`${baseUrl}/modify/main/skill/basic`, payload)
  },
  //获取主技能下的子技能信息
  getSubSkillInfo(payload) {
    return axios.get(`${baseUrl}/get/sub/skill/list?mainSkillId=${payload}`)
  },
  //添加/修改子技能
  updateSubSkillInfo(payload) {
    return axios.post(`${baseUrl}/modify/sub/skill`, payload)
  },
  //删除子技能
  deleteSubSkill(payload) {
    return axios.post(`${baseUrl}/delete/sub/skill`, payload)
  },
  //添加/修改子技能课程推荐
  updateSubSkillCourse(payload) {
    return axios.post(`${baseUrl}/modify/course/recommendation`, payload)
  },
  //删除子技能课程推荐
  deleteSubSkillCourse(payload) {
    return axios.post(`${baseUrl}/delete/course/recommendation`, payload)
  },
  //导出岗位的主技能子技能详情
  exportSkillDetail(postId) {
    return axios.get(`${baseUrl}/download/post-skill?postId=${postId}`, {
      responseType: 'blob'
    })
  },
  //根据题库标签获取技能信息
  getSkillQuestionInfoById(payload) {
    return axios.post(`${baseUrl}/search/post/skill/question`, payload)
  }
}
