import config from '../config/config'
import { axios } from './index'

const baseUrl = config.baseUrl + '/file/api/v1'
let questionUrl = config.baseUrl + '/question/api/v1/files'
export default {
  //下载导入题目模版 0: excel 1: word
  downloadQuestionTemplate(payload) {
    return axios.get(`${questionUrl}/download/template?type=${payload}`, {
      responseType: 'blob'
    })
  },
  //上传导入题目 0: excel 1: word
  importQuestion(payload, file) {
    return axios.post(`${questionUrl}/upload?type=${payload}`, file, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  uploadServeFile(file) {
    return axios.post(`${baseUrl}/upload/course/file`, file)
  },
  judgeUpload() {
    return axios.get(`${baseUrl}/upload/type`)
  }
}
