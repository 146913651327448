import config from '../config/config'
import { axios } from './index'

const examManageUrl = config.baseUrl + '/exam/api/v1/exam'

export default {
  //获取考试考试报告中的评价
  getExamComment(payload) {
    return axios.get(`${examManageUrl}/get/exam/comment?examId=${payload}`)
  }
}
