import config from '@/config/config'
import { axios } from '@/api/index'

const newExerciseExamUrl = config.baseUrl + '/exam/api/v2/exercise/exam'

export default {
  //根据examId查看试卷(根据技能分组，新表)
  getPaperAbility(examId) {
    return axios.get(`${newExerciseExamUrl}/paper/ability?examId=${examId}`)
  },
  judgeHasPaperId(examId) {
    // 判断是否存在试卷
    return axios.get(`${newExerciseExamUrl}/paper/get?examId=${examId}`)
  },
  //根据examId查看试卷(根据技能分组)
  getExamAbility(examId) {
    return axios.get(`${newExerciseExamUrl}/paper/ability/get?examId=${examId}`)
  }
}
