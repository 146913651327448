const infoJS = [
  {
    name: 'name',
    key: '姓名',
    value: ''
  },
  {
    name: 'jobNumber',
    key: '工号',
    value: ''
  },
  {
    name: 'score',
    key: '测评得分',
    value: ''
  },
  {
    name: 'fullScore',
    key: '试卷满分',
    value: ''
  },
  {
    name: 'duration',
    key: '测评用时',
    value: ''
  },
  {
    name: 'direction',
    key: '测评方向',
    value: ''
  },
  {
    name: 'avgScore',
    key: '方向平均分',
    value: ''
  },
  {
    name: 'ranking',
    key: '方向内排名',
    value: ''
  }
]

const columnarJS = {
  color: ['#3669ab', '#d65252'],
  legend: {
    data: ['得分', '失分'],
    left: '50%'
  },
  tooltip: {},
  xAxis: {
    data: ['判断题', '单选题', '多选题', '填空题', '综合题'],
    name: '',
    axisLine: { onZero: true },
    splitLine: { show: false },
    splitArea: { show: false }
  },
  yAxis: {},
  grid: {
    bottom: 20
  },
  series: [
    {
      name: '得分',
      type: 'bar',
      stack: 'total',
      label: {
        color: '#FFFFFF',
        fontSize: '18px',
        show: true
      },
      barWidth: 40,
      data: []
    },
    {
      name: '失分',
      type: 'bar',
      stack: 'total',
      label: {
        color: '#FFFFFF',
        fontSize: '18px',
        show: true
      },
      barWidth: 40,
      data: []
    }
  ]
}

const abilityTable = []

const abilityRadar = {
  title: {
    text: '方向岗位匹配度',
    left: 'center'
  },
  radar: {
    z: 2,
    radius: 120,
    startAngle: 90,
    splitNumber: 5, // 环数
    splitLine: {
      show: true
    },
    // axisLine: {
    //   lineStyle: {
    //     color: '#ccc'
    //   }
    // },
    // splitArea: {
    //   areaStyle: {
    //     color: ['#fff']
    //   }
    // },
    name: {
      show: true,
      fontSize: 14,
      color: '#000'
    },
    indicator: [],
    center: []
  },
  series: [
    {
      type: 'radar',
      silence: true,
      symbol: false,
      data: [],
      itemStyle: {}
    }
  ]
}

export { infoJS, columnarJS, abilityTable, abilityRadar }
